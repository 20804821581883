import {create} from 'zustand';

export const dadosLogin = create((set) => ({
    ifLogado: "notLogad",
  
    changeIfLogado: (email) => set((state)=> ({        
        ifLogado: email,
    })),
       changeUser: (email) => set((state)=> ({            //para o usuario aparecer na navbar
        usuario: email,
        
    }))   
       
   
}))





