import api from "../../services/api.js";
import { useEffect, useState } from "react";
import "../franqueados-docs/franqueados-docs.css"
import { Link } from "react-router-dom";


function FranqueadosDocs() {

  const [dados, setDados] = useState([]);
  const [id_franqueado, setId_franqueado] = useState(0);
  const [id_doc_franqueado, setId_doc_franqueado] = useState(0);
  const [url_doc_franqueado, setUrl_doc_franqueado] = useState("");
  const [descr_doc, setDescr_doc] = useState("");
  const [controle_doc_franqueado, setControle_doc_franqueado] = useState("");

  function PesquisarImgContrato() {


    let nome_fran = localStorage.getItem('nome_franqueado');      
    let id_franqueado = localStorage.getItem('id_franqueado');

    api.get("/franqueados_doc_url", { params: { id_franqueado} })
      .then((resp) => {
        setDados(resp.data);
        // console.log(resp.data)
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }


  function ChangeUrl(e) {
    setUrl_doc_franqueado(e.target.value);
  }

  useEffect(() => {
    PesquisarImgContrato();
  
  }, []);


  return <>
    <div className="container-fluid pt-2 ">
      <div className=" d-flex justify-content-between">
        <h3 className="d-flex">Documentos Franqueado</h3>
        <h4>
          Franqueado: {localStorage.getItem('id_franqueado')}
        </h4>
        <Link to="/franqueados" className="btn btn-outline-success p-2 botaoS mt-1 me-5" >Voltar </Link>
      </div>
      <div className="container-fluid pt-2 rounded-3 conteiner ">
        <div className="col-8 ms-4 mb-3">
          <select className="form-control " name="url" id="url" onChange={ChangeUrl}>
            <option value="">Selecione</option>
            {
              dados.map((item) => {
                return <option key={item.id_doc_franqueado} value={item.url_doc_franqueado}>{item.descr_doc}</option>
              })
            }
          </select>
        </div>

        <div>
          <iframe className=" col-11 frame ms-4" src={url_doc_franqueado}></iframe>
        </div>
      </div>
    </div>
  </>
}
export default FranqueadosDocs;