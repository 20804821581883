import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import FranquiasCad from "../franquias-cad/franquias-cad.jsx";
import api from "../../services/api.js";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../franquias/franquias.css";
import franquiasPDF from "../../report/report.franquias/report.franquias.jsx";
import Navbar from "../../components/navbar/navbar.jsx";
import { dadosLogin } from "../../zustand/zuztand.js"


function Franquias() {
    const navigate = useNavigate();

    const [buscaCnpj, setBuscaCnpj] = useState("");
    const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
    const [dados, setDados] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);
    // const [pegaTurma, setPegaTurma] = useState("");

    const [regiao, setRegiao] = useState("");
    const [tipo_franquia, setTipo_franquia] = useState("");
    const [buscaFranquia, setBuscaFranquia] = useState("");
    const [buscaAbrang, setBuscaAbrang] = useState("");

    const [ufPesq, setUfPesq] = useState("");

    const { ifLogado, usuario } = dadosLogin()

    const columns = [
        {
            name: 'Código',
            selector: row => ("0000" + row.id_franquia).slice(-4),  //para colocar zero a esquerda
            sortable: true,
            width: "9%",
        },
        {
            name: 'Nome Franquia',
            selector: row => row.razao_franquia,
            sortable: true,
            width: "28%",
            compact: true
        },
        {
            name: 'Telefone.',
            selector: row => row.tel_franquia,

            sortable: true,
            width: "11%",
            compact: true
        },
        {
            name: 'CNPJ',
            selector: row => row.cnpj_franquia,
            sortable: true,
            width: "15%",
            right: true
        },
        {
            name: 'Franqueado(os)',
            selector: row => row.nome_franqueados,
            width: "20%",
            sortable: true
        },
        {
            cell: (row) => <>                
                <button onClick={() => EditarFranquia(row.id_franquia)} className="btn btn-primary ms-3"><i className="bi bi-pencil-square"></i></button>
                {
                    usuario === "ariscoz@gmail.com" ?
                        <button onClick={() => AltStatusFranquia(row.id_franquia)} className="btn btn-danger ms-3"><i className="bi bi-trash3-fill"></i></button> : null
                }               

            </>,
            width: "15%",
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos'
    };

    function AltStatusFranquia(id) {
        let id_franquia = id;

        const param2 = {
            status_franquia: "I",
        }
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão da Franquia?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api.put("/franquias_edit_status/" + id_franquia, param2)
                            .then((resp) => {
                                if (resp.status === 200) {

                                    ListarFranquias(localStorage.getItem("id_usuario"), regiao, 1, qtd_reg_pagina);

                                } else {
                                    alert("Erro ao editar dados");
                                }
                            })
                            .catch((err) => {
                                alert("Erro ao editar dados");
                            });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    function NovaFranquia() {

        const event = new CustomEvent("openFranquias", {
            detail: {
                operacao: "new"
            }
        });
        window.dispatchEvent(event);
    }

    function EditarFranquia(id) {

        localStorage.setItem('id_franquia', id);
        const event = new CustomEvent("openFranquias", {
            detail: {
                operacao: "edit",
                id_franquia: id
            }
        });
        window.dispatchEvent(event);
    }

    function EditarFranquiaSalvo() { //para abrir o ultimo visualizado

        var idf = localStorage.getItem('id_franquia');
        const event = new CustomEvent("openFranquias", {
            detail: {
                operacao: "edit",
                id_franquia: idf
            }
        });
        window.dispatchEvent(event);
    }


    function ListarFranquias(id_usuario, regiao, pagina, qtd_reg_pagina) {

        api.get("/franquias_listar", { params: { id_usuario, regiao, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDados(resp.data.dados);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }


    function ListarFranquiasTipo(id_usuario, tipo_franquia, pagina, qtd_reg_pagina) {

        api.get("/franquias_listar_tipo", { params: { id_usuario, tipo_franquia, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDados(resp.data.dados);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }
    function ListarFranquiasUf(id_usuario, uf_franquia, pagina, qtd_reg_pagina) {

        api.get("/franquias_listar_uf", { params: { id_usuario, uf_franquia, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDados(resp.data.dados);
                // console.log(resp.data.dados)
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }



    function PesquisarFranquiaNome() {

        api.get("/franquia_busca/", { params: { buscaFranquia } })
            .then((resp) => {
                setDados(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }
    function PesquisarFranquiaCnpj() {

        api.get("/franquia_busca_cnpj/", { params: { buscaCnpj } })
            .then((resp) => {
                setDados(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFranquiaAbrang() {

        api.get("/franquia_busca_abrang/", { params: { buscaAbrang } })
            .then((resp) => {
                setDados(resp.data);
                console.log(buscaAbrang)
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function AtualizaTabFranquias(e) {
        ListarFranquias(localStorage.getItem("id_usuario"), "", 1, 100);// btn mostrar todos
    }

    function ChangeRegiao(e) {
        setTipo_franquia("Tipo");
        setRegiao(e.target.value);
        ListarFranquias(localStorage.getItem("id_usuario"), e.target.value, 1, 100);
    }
    function ChangeUf(e) {
        setUfPesq(e.target.value);

        ListarFranquiasUf(localStorage.getItem("id_usuario"), e.target.value, 1, 100);

    }

    function ChangeTipo_franquia(e) {
        setTipo_franquia(e.target.value);
        ListarFranquiasTipo(localStorage.getItem("id_usuario"), e.target.value, 1, 100);
    }

    function ChangePerRows(newQtd, page) {
        ListarFranquias(localStorage.getItem("id_usuario"), regiao, page, newQtd);
        setQtdRegPagina(newQtd);
    }

    function ChangePage(page) {
        ListarFranquias(localStorage.getItem("id_usuario"), regiao, page, 100);
    }

    function AtualizaDados() {
        ListarFranquias(localStorage.getItem("id_usuario"), regiao, 1, 100);
    }


    function Pegarid_franquia(id) {
        localStorage.setItem('id_franquia', id);

    }

    useEffect(() => {
        if (buscaFranquia === "") {
            ListarFranquias(localStorage.getItem("id_usuario"), regiao, 1, 100);
        } else {
            PesquisarFranquiaNome();
        }
    }, [buscaFranquia]);

    useEffect(() => {
        if (buscaCnpj === "") {
        } else {
            PesquisarFranquiaCnpj();
        }
    }, [buscaCnpj]);


    useEffect(() => {
        if (buscaAbrang === "") {
        } else {
            PesquisarFranquiaAbrang();
        }
    }, [buscaAbrang]);

    useEffect(() => {
        localStorage.setItem("id_usuario", 1)
    }, []);

    return <>
        <div className="d-flex container-principal">
            {
                ifLogado != "notLogad" ? <>
                    <FranquiasCad onClose={AtualizaDados} />
                    <Navbar tela="franquias" />

                    <div className="container-fluid margem-top">
                        <div className="row flex-nowrapl">

                            <div className="col py-3 me-3">
                                <div className="input-group justify-content-between">
                                    <div className="col-2 ms-2">

                                    </div>
                                    <div className="col-6">
                                        <div className="input-group justify-content-end">
                                            <input onChange={(e) => setBuscaFranquia(e.target.value)} type="text" className="form-control buscainput-fran" id="pesq" placeholder="Pesquisar Franquia" value={buscaFranquia} />
                                            <button onClick={AtualizaTabFranquias} className="btn btn-primary mb-2" type="button" id="button-addon2">Mostrar Todas</button>
                                            <button onClick={EditarFranquiaSalvo} className="btn btn-primary mb-2 bi bi-arrow-left-square ms-2" type="button" id="button-addon2 "></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white p-4 rounded-4 border  titulo-tabela1">
                                    <div className="d-flex justify-content-between  mb-3  titulo-tabela">

                                        <div className="form-control div_regiao">
                                            <select name="reg" id="reg" onChange={ChangeRegiao}>
                                                <option value="">Região</option>
                                                <option value="Norte">Norte</option>
                                                <option value="Sul">Sul</option>
                                                <option value="Sudeste">Sudeste</option>
                                                <option value="Nordeste">Nordeste</option>
                                                <option value="Centro-Oeste">Centro-Oeste</option>

                                            </select>
                                        </div>

                                        <div className="col-1 div_uf ms-3 me-3">

                                            <select type="text" className="form-control " onChange={ChangeUf} >
                                                <option value="">UF</option>
                                                <option value="AC">AC</option>
                                                <option value="AL">AL</option>
                                                <option value="AP">AP</option>
                                                <option value="AM">AM</option>
                                                <option value="BA">BA</option>
                                                <option value="CE">CE</option>
                                                <option value="DF">DF</option>
                                                <option value="ES">ES</option>
                                                <option value="GO">GO</option>
                                                <option value="MA">MA</option>
                                                <option value="MS">MS</option>
                                                <option value="MT">MT</option>
                                                <option value="MG">MG</option>
                                                <option value="PA">PA</option>
                                                <option value="PB">PB</option>
                                                <option value="PR">PR</option>
                                                <option value="PE">PE</option>
                                                <option value="PI">PI</option>
                                                <option value="RJ">RJ</option>
                                                <option value="RN">RN</option>
                                                <option value="RS">RS</option>
                                                <option value="RO">RO</option>
                                                <option value="RR">RR</option>
                                                <option value="SC">SC</option>
                                                <option value="SP">SP</option>
                                                <option value="SE">SE</option>
                                                <option value="TO">TO</option>
                                            </select>
                                        </div>

                                        <div className="form-control div_tipo ">
                                            <select name="reg" id="reg" onChange={ChangeTipo_franquia} >
                                                <option value="">Mod.</option>
                                                <option value="Pequena">Pequena</option>
                                                <option value="Média">Média</option>
                                                <option value="Grande">Grande</option>

                                            </select>
                                        </div>

                                        <div className="d-flex" >
                                            <small className="text-secundary fw-bold mt-2 ms-1">Cidades</small>
                                            <input type="text" className="form-control cnpj " onChange={(e) => setBuscaAbrang(e.target.value)} />

                                        </div>
                                        <div className="d-flex" >
                                            <small className="text-secundary fw-bold mt-2 me-1 ">CNPJ</small>
                                            <input type="text" className="form-control cnpj" onChange={(e) => setBuscaCnpj(e.target.value)} placeholder="99.999.999/9999-99" />
                                        </div>

                                        <div className="align-items-end">
                                            <button onClick={(e) => franquiasPDF(dados)} className="btn btn-danger btn-fran-imprimir me-1 "><i className="bi bi-file-earmark-pdf "></i>Imprimir</button>
                                            {
                                                usuario === "ariscoz@gmail.com" ?
                                                    <button onClick={NovaFranquia} className="btn btn-primary btn-fran-novo  me-1 ms-1">+ Franquia</button> : null
                                            }
                                        </div>
                                    </div>


                                    <DataTable className="tabela-franquias" columns={columns}
                                        data={dados}
                                        pagination={false}                                      
                                        noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                                                              
                                      
                                    />
                                    <div className="d-flex mt-3 justify-content-end me-5"> 
                                    <small>Total de registros: {dados.length}</small>
                                    </div>
                                    

                                </div>

                            </div>
                        </div>
                    </div></> : navigate('/login')
            }
        </div>


    </>
}
export default Franquias;