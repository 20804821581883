import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import FornecedoresCad from "../fornecedores-cad/fornecedores-cad.jsx";
import api from "../../services/api.js";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../fornecedores/fornecedores.css";
import fornecedoresPDF from "../../report/report.fornecedores/report.fornecedores.jsx";
import Navbar from "../../components/navbar/navbar.jsx";
import { dadosLogin } from "../../zustand/zuztand.js"



function Fornecedores() {
    const navigate = useNavigate();
    const [buscaCnpj, setBuscaCnpj] = useState("");
    const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
    const [dadosForn, setDadosForn] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);



    const [regiao_forn, setRegiao_forn] = useState("");
    const [clas_fornecedor, setClas_fornecedor] = useState("");
    const [buscaFornecedor, setBuscaFornecedor] = useState("");
    const [buscaProduto, setBuscaProduto] = useState("");
    const { ifLogado, usuario } = dadosLogin();

    const columns = [
        {
            name: 'Código',
            selector: row => ("0000" + row.id_fornecedor).slice(-4), //para colocar zero a esquerda
            sortable: true,
            width: "9%",
        },
        {
            name: 'Nome Fornecedor',
            selector: row => row.nome_fornecedor,
            sortable: true,
            width: "25%",
            compact: true
        },
        {
            name: 'Telefone.',
            selector: row => row.tel_fornecedor,
            sortable: true,
            width: "10%",
            compact: true
        },
        {
            name: 'CNPJ',
            selector: row => row.cnpj_fornecedor,
            sortable: true,
            width: "15%",
        },
        {
            name: 'Contatos',
            selector: row => row.contato_fornecedor,
            width: "25%",
            sortable: true
        },
        {
            cell: (row) => <>
                <button onClick={() => EditarFornecedor(row.id_fornecedor)} className="btn btn-primary ms-3"><i className="bi bi-pencil-square"></i></button>
                {
                    usuario === "ariscoz@gmail.com" ?
                        <button onClick={() => AltStatusFornecedor(row.id_fornecedor)} className="btn btn-danger ms-3"><i className="bi bi-trash3-fill"></i></button> : null
                }

            </>,
            width: "15%",
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos'
    };

    function AltStatusFornecedor(id) {
        let id_fornecedor = id;

        const param2 = {
            status_fornecedor: "I",
        }
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão da Fornecedor?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api.put("/fornecedores_edit_status/" + id_fornecedor, param2)
                            .then((resp) => {
                                if (resp.status === 200) {

                                    ListarFornecedor(localStorage.getItem("id_usuario"), "", 1, qtd_reg_pagina);

                                } else {
                                    alert("Erro ao editar dados");
                                }
                            })
                            .catch((err) => {
                                alert("Erro ao editar dados");
                            });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    function NovoFornecedor() {

        const event = new CustomEvent("openFornecedor", {
            detail: {
                operacao: "new"
            }
        });
        window.dispatchEvent(event);
    }

    function EditarFornecedor(id) {

        localStorage.setItem('id_openFornecedor', id);
        const event = new CustomEvent("openFornecedor", {
            detail: {
                operacao: "edit",
                id_fornecedor: id
            }
        });
        window.dispatchEvent(event);
    }

    function EditarFornecedorSalvo() { //para abrir o ultimo visualizado
        var idf = localStorage.getItem('id_fornecedor');

        const event = new CustomEvent("openFornecedor", {
            detail: {
                operacao: "edit",
                id_fornecedor: idf
            }
        });
        window.dispatchEvent(event);
    }

    function Pegarid_fornecedor(id) {
        localStorage.setItem('id_fornecedor', id);
    }

    function ListarFornecedor(id_usuario, regiao_forn, pagina, qtd_reg_pagina) {

        api.get("/fornecedores_listar", { params: { id_usuario, regiao_forn, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDadosForn(resp.data.dados);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarFornecedorTipo(id_usuario, clas_fornecedor, pagina, qtd_reg_pagina) {

        api.get("/fornecedores_listar_tipo", { params: { id_usuario, clas_fornecedor, pagina, qtd_reg_pagina } })
            .then((resp) => {
                setTotalRegistros(resp.data.total_registros);
                setDadosForn(resp.data.dados);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFornecedorNome() {
        api.get("/fornecedores_busca/", { params: { buscaFornecedor } })
            .then((resp) => {
                setDadosForn(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFornecedorProduto() {
        api.get("/fornecedores_produtos/", { params: { buscaProduto } })
            .then((resp) => {
                setDadosForn(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFornecedorCnpj() {
        api.get("/fornecedores_busca_cnpj/", { params: { buscaCnpj } })
            .then((resp) => {
                setDadosForn(resp.data);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDadosForn([]);
                alert("Erro ao carregar dados");
            });
    }

    function AtualizaTabFornecedor(e) {
        ListarFornecedor(localStorage.getItem("id_usuario"), clas_fornecedor, 1, qtd_reg_pagina);// btn mostrar todos
    }

    function ChangeRegiao(e) {
        setClas_fornecedor("Selecione");
        setRegiao_forn(e.target.value);
        ListarFornecedor(localStorage.getItem("id_usuario"), e.target.value, 1, qtd_reg_pagina);
    }

    function ChangeClasFornecedor(e) {
        setClas_fornecedor(e.target.value);
        ListarFornecedorTipo(localStorage.getItem("id_usuario"), e.target.value, 1, qtd_reg_pagina);
    }

    function ChangePerRows(newQtd, page) {
        ListarFornecedor(localStorage.getItem("id_usuario"), regiao_forn, page, newQtd);
        setQtdRegPagina(newQtd);
    }

    function ChangePage(page) {
        ListarFornecedor(localStorage.getItem("id_usuario"), regiao_forn, page, qtd_reg_pagina);
    }

    function AtualizaDados() {
        ListarFornecedor(localStorage.getItem("id_usuario"), regiao_forn, 1, qtd_reg_pagina);
    }

    useEffect(() => {
        if (buscaFornecedor === "") {
            ListarFornecedor(localStorage.getItem("id_usuario"), regiao_forn, 1, qtd_reg_pagina);
        } else {
            PesquisarFornecedorNome();
        }
    }, [buscaFornecedor]);

    useEffect(() => {
        if (buscaProduto === "") {
        } else {
            PesquisarFornecedorProduto();
        }
    }, [buscaProduto]);

    useEffect(() => {
        if (buscaCnpj === "") {
        } else {
            PesquisarFornecedorCnpj();
        }
    }, [buscaCnpj]);

    useEffect(() => {
        localStorage.setItem("id_usuario", 1)
        ListarFornecedor(localStorage.getItem("id_usuario"), clas_fornecedor, 1, qtd_reg_pagina);
    }, []);

    return <>
        {
            ifLogado != "notLogad" ? <>
                <FornecedoresCad onClose={AtualizaDados} />
                <div className="container-fluid margem-top-fornec ms-4">
                    <Navbar tela="fornecedores" />
                    <div className="row flex-nowrapl me-5">
                        <div className="col py-3 me-3">
                            <div className="input-group justify-content-end">
                                <input onChange={(e) => setBuscaFornecedor(e.target.value)} type="text" className="form-control buscainput-fran" id="pesq" placeholder="Pesquisar Fornecedor" value={buscaFornecedor} />
                                <button onClick={AtualizaTabFornecedor} className="btn btn-primary mb-2" type="button" id="button-addon2">Mostrar Todos</button>
                                <button onClick={EditarFornecedorSalvo} className="btn btn-primary mb-2 bi bi-arrow-left-square ms-2" type="button" id="button-addon2 "></button>
                            </div>
                            <div className="bg-white p-4 rounded-4 border ">
                                <div className="d-flex justify-content-between  mb-3  titulo-tabela">
                                    <small className="text-secundary fw-bold mt-2 ">Região</small>
                                    <div className="form-control div_regiao_forn">
                                        <select name="reg" id="reg" onChange={ChangeRegiao}>
                                            <option value="">Todas as regiões</option>
                                            <option value="Norte">Norte</option>
                                            <option value="Sul">Sul</option>
                                            <option value="Sudeste">Sudeste</option>
                                            <option value="Nordeste">Nordeste</option>
                                            <option value="Centro-Oeste">Centro-Oeste</option>
                                        </select>
                                    </div>
                                    <div className="d-flex ">
                                        <small className="text-secundary fw-bold mt-2 me-1">Produtos</small>
                                        <input onChange={(e) => setBuscaProduto(e.target.value)} type="text" className="form-control buscainput-fran" id="pesq" placeholder="Ex: mobiliário" value={buscaProduto} />
                                    </div>
                                    <div className="d-flex" >
                                        <small className="text-secundary fw-bold mt-2 me-1">CNPJ</small>
                                        <input type="text" className="form-control  ms-2" onChange={(e) => setBuscaCnpj(e.target.value)} placeholder="99.999.999/9999-99" />

                                    </div>
                                    <div className="align-items-end">

                                        <button onClick={(e) => fornecedoresPDF(dadosForn)} className="btn btn-danger ms-4"><i className="bi bi-file-earmark-pdf me-2"></i>Imprimir</button>
                                        {
                                            usuario === "ariscoz@gmail.com" ?
                                                <button onClick={NovoFornecedor} className="btn btn-primary btn-fran-novo  ms-4 me-4">Novo Fornecedor</button> : null
                                        }
                                    </div>
                                </div>
                                <DataTable className="tabela-franquias" columns={columns}
                                    data={dadosForn}
                                    pagination={true}
                                    paginationComponentOptions={paginationOptions}
                                    noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                                    paginationServer={true}
                                    paginationTotalRows={total_registros}
                                    onChangeRowsPerPage={ChangePerRows}
                                    onChangePage={ChangePage}
                                />

                            </div>
                        </div>
                    </div>
                </div></> : navigate('/login')
        }
    </>
}
export default Fornecedores;