import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./pages/login/login.jsx";
import Dashboard from "./pages/dashboard/dashboard.jsx";
import Manutencao from "./pages/manutencao/manutencao.jsx";
import Franqueados from "./pages/franqueados/franqueados.jsx";
import Franquias from "./pages/franquias/franquias.jsx";
import Fornecedores from "./pages/fornecedores/fornecedores.jsx";
import FranquiaFotos from "./pages/franquia-fotos/franquia-fotos.jsx";
import FornecedoresDocs from "./pages/fornecedrores-docs/fornecedores-docs..jsx";
import FranqueadosDocs from "./pages/franqueados-docs/franqueados-docs.jsx";
import FranquiasDocs from "./pages/franquias-docs/franquias-docs.jsx";
import Aniversarios from "./pages/aniversarios/aniversarios.jsx";
import Colaboradores from "./pages/colaboradores/colaboradores.jsx";
import MenuFranquias from "./pages/menufranquias/menu-franquias.jsx";
import MenuFornecedores from "./pages/menufornecedores/menu-fornecedores.jsx";
import MenuAniversariantes from "./pages/menuaniversariantes/menu-aniversariantes.jsx";
import MenuColaboradores from "./pages/menucolaboradores/menu-colaboradores.jsx";
import MenuCalendario from "./pages/menucalendario/menu-calendario.jsx";
import FranquiasContratos from "./pages/franquias-contratos/franquias-contratos.jsx";
import FornecedoresContratos from "./pages/fornecedores-contratos/fornecedores-contratos.jsx";
import ColaboradoresContratos from "./pages/colaboradores-contratos/colaboradores-contratos.jsx";
import AniversarioColaboradores from "./pages/aniversario-colaboradores/aniversario-colaboradores .jsx";
import AniversarioCidades from "./pages/aniversario-cidades/aniversario-cidades.jsx";
import AniversarioFranquias from "./pages/aniversario-franquias/aniversario-franquias.jsx";
import AniversarioFranqueados from "./pages/aniversario-franqueados/aniversario-franqueados.jsx";
import ColaboradoresAutorizacoes from "./pages/colaboradores-autorizacoes/colaboradores-autorizacoes.jsx";
import FranquiaFotoPesq from "./pages/franquia-fotos-pesq/franquia-fotos-pesq.jsx";
import CalendarioGeral from "./pages/calendario_geral/calendario_geral.jsx";
import CalendarioAniver from "./pages/calendario_aniver/calendario_aniver.jsx";
import CalendarioFerias from "./pages/calendario_ferias/calendario_ferias.jsx";
import CalendarioConvencao from "./pages/calendario_convencao/calendario_convencao.jsx";
import CalendarioInalguracoes from "./pages/calendario_inalguracoes/calendario_inalguracoes.jsx";

function Rotas(){
    return <BrowserRouter>
        <Routes>           
            <Route path="/" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />   
            <Route path="/manutencao" element={<Manutencao />} />  
            <Route path="/franqueados" element={<Franqueados/>} />
            <Route path="/franquias" element={<Franquias/>} />
            <Route path="/fornecedores" element={<Fornecedores/>} />          
            <Route path="/franquia-fotos" element={<FranquiaFotos/>} />
            <Route path="/fornecedores" element={<Fornecedores/>} />        
            <Route path="/fornecedores-docs" element={<FornecedoresDocs/>} />
            <Route path="/franqueados-docs" element={<FranqueadosDocs/>} />
            <Route path="/franquias-docs" element={<FranquiasDocs/>} />              
            <Route path="/aniversarios" element={<Aniversarios/>} />
            <Route path="/colaboradores" element={<Colaboradores/>} />
            <Route path="/menu-franquias" element={<MenuFranquias/>} />
            <Route path="/menu-fornecedores" element={<MenuFornecedores/>} />
            <Route path="/menu-aniversariantes" element={<MenuAniversariantes/>} />
            <Route path="/menu-colaboradores" element={<MenuColaboradores/>} />
            <Route path="/menu-calendario" element={<MenuCalendario/>} />
            <Route path="/franquias-contratos" element={<FranquiasContratos/>} />  
            <Route path="/fornecedores-contratos" element={<FornecedoresContratos/>} /> 
            <Route path="/colaboradores-contratos" element={<ColaboradoresContratos/>} />    
            <Route path="/aniversario-colaboradores" element={<AniversarioColaboradores/>} />    
            <Route path="/aniversario-cidades" element={<AniversarioCidades/>} />  
            <Route path="/aniversario-franquias" element={<AniversarioFranquias/>} />  
            <Route path="/aniversario-franqueados" element={<AniversarioFranqueados/>} /> 
            <Route path="/colaboradores-autorizacoes" element={<ColaboradoresAutorizacoes/>} /> 
            <Route path="/franquia-fotos-pesq" element={<FranquiaFotoPesq/>} /> 
            <Route path="/calendario-geral" element={<CalendarioGeral/>} /> 
            <Route path="/calendario-aniver" element={<CalendarioAniver/>} /> 
            <Route path="/calendario-ferias" element={<CalendarioFerias/>} /> 
            <Route path="/calendario-convencao" element={<CalendarioConvencao/>} /> 
            <Route path="/calendario-inalguracoes" element={<CalendarioInalguracoes/>} />           
         
        </Routes>
    </BrowserRouter>
}

export default Rotas;