import api from "../../services/api.js";
import { useEffect, useState } from "react";
import "../colaboradores-contratos/colaboradores-contratos.css"
import { Link } from "react-router-dom";
import AutocompleteColaboradores from "../../components/autocomplete-colaboradores/autocomplete-colaboradores.jsx";

function ColaboradoresContratos() {

  const [dados, setDados] = useState([]);
  const [url_doc_colaborador, setUrl_doc_colaborador] = useState("");
  const [dadosCombo, setDadosCombo] = useState([]);

  const [codigoCol, setCodigoCol] = useState(0);
  const [nome_colaborador, setNome_colaborador] = useState("");
  const [texto, setTexto] = useState("");
  const [escolha, setEscolha] = useState(true);

  function PesquisarImgContrato() {//pesquisa ao selecionar a colaborador no combobox

    let id_colab_fran = localStorage.getItem('id_colaborador_pesq');
    api.get("/colaborador_doc_url", { params: { id_colab_fran } })
      .then((resp) => {
        let novoarray = []
        resp.data.map((item) => {
          let tipo_doc = item.tipo_doc;
          if (tipo_doc === 'contrato') {
            novoarray.push({
              url_doc_col: item.url_doc_col,
              descr_doc_col: item.descr_doc_col,
            })
          }
        });
        setDados(novoarray);
        //console.log(resp.data)
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados ");
      });
  }

  function PesquisarImgContratoDigitado() {//pesquisa pelo nome do colaborador digitado no bd

    let id_colab_fran = codigoCol;
    api.get("/colaborador_doc_url", { params: { id_colab_fran } })
      .then((resp) => {
        setDados(resp.data);
        //console.log(resp.data)
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }

  function ChangeUrl(e) {
    setUrl_doc_colaborador(e.target.value);
  }

  function ChangeId_colaborador(e) {
    setUrl_doc_colaborador("");  
    setDados([])
    localStorage.setItem('id_colaborador_pesq', e.target.value);
    PesquisarImgContrato();
  }

  function ListarColaboradorGeralNome() {//somente id e nome para montar combo
    let busca = ""
    api.get("/colaboradores_busca/", { params: { busca } })
      .then((resp) => {
        //console.log(resp.data)
        setDadosCombo(resp.data);
      })
      .catch((err) => {
        setDadosCombo([])
        alert("Erro ao montar combo");
      });
  }
  function escolher() { // para alterar o estado escolha e esconder imput
    setUrl_doc_colaborador('');
    setEscolha(!escolha);
  }

  useEffect(() => {
    ListarColaboradorGeralNome();

  }, []);

  useEffect(() => {
    PesquisarImgContratoDigitado();

  }, [codigoCol]);

  return <>
    <div className="d-flex justify-content-center container-fundo-col-contratos">
      <div className="d-inline justify-content-center container-body-col-contratos">
        <div className="d-flex col-11 mt-3" >
          <div className="d-flex col-12">
            <div className="col-2 ms-3">
              <button className="btn btn-outline-primary ms-4 " onClick={escolher}>Digitar/Selecionar</button>
            </div>
            {
              escolha === true ?
                <div className="col-4 ">
                  < AutocompleteColaboradores placeholder="Digite o nome do colaborador...."
                    onClickId={setCodigoCol} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                    onClickNome={setNome_colaborador} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                    onClickTexto={setTexto} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                    onClickUrl={setUrl_doc_colaborador}
                    value={nome_colaborador}
                  />
                </div> : null
            }

            {
              escolha === false ?
                <div className="col-4 ">
                  <select className="form-control " name="url" id="url" onChange={ChangeId_colaborador}>
                    <option value="">Selecione o colaborador</option>
                    {
                      dadosCombo.map((item) => {
                        return <option key={item.id_colab_fran} value={item.id_colab_fran}>{`${item.id_colab_fran} - ${item.nome_colaborador} `}</option>
                      })
                    }
                  </select>
                </div> : null
            }

            <div className="col-2 ms-4 mb-3">
              <select className="form-control" name="url" id="url" onChange={ChangeUrl}>
                <option value="">Selecione o contrato</option>
                {
                  dados.map((item) => {
                    return <option key={item.id_doc_colaborador} value={item.url_doc_col}>{item.descr_doc_col}</option>
                  })
                }
              </select>
            </div>
          </div>
          <div className="col-2 ms-3">
            <Link to="/menu-colaboradores" className="btn btn-success p-2 me-5 btn-sm" >Voltar </Link>
          </div>
        </div>
        <div className="d-flex justify-content-center container-body-col-contratos overflow-auto">
          
          <div className="  d-block">
            <div className=" col-11">
              <div>
                <iframe className=" col-11 frame-contratos ms-4" src={url_doc_colaborador}></iframe>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </>
}
export default ColaboradoresContratos;