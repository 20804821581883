import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'; // Importa o plugin de visualização de grade diária
import interactionPlugin from '@fullcalendar/interaction'; // Importa o plugin de interação (necessário para o clique)

import "../calendario/calendario.css"
import { useEffect, useState } from "react";
import api from "../../services/api.js";
import { dadosLogin } from "../../zustand/zuztand.js"
import calendarioPDF from '../../report/report.calendario/report.calendario.jsx';
import { assign } from 'pdfmake/build/pdfmake.js';

export default function Calendario(props) {
    const [dados, setDados] = useState([]);
    const [dadosTemp, setDadosTemp] = useState([]);
    const [dadosMes, setDadosMes] = useState([]);
    const [dadosMesTemp, setDadosMesTemp] = useState([]);

    const [title, setTitle] = useState("");
    const [date, setDate] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [color, setColor] = useState("");
    const [descr_event, setDescr_event] = useState("");
    const [id_event, setId_event] = useState(0);

    const [horaInicio, setHoraInicio] = useState("");
    const [horaFim, setHoraFim] = useState("");
    const [tipo, setTipo] = useState("");
    const [mes, setMes] = useState("");
    const [atualizadadostemp, setAtualizadadostemp] = useState(false);

   

    const { ifLogado, usuario } = dadosLogin()


    function ListarTodosEventos() {
     
        api.get("/events_listar")
            .then((resp) => {
                //console.log(resp.data)
                let arrayTotal = [];
                resp.data.map((item) => {
                    arrayTotal.push(
                        {
                            id_event: item.id_event,
                            title: item.title,
                            date: item.date,
                            start: item.start,
                            end: item.end,
                            color: item.color,
                            horaIni: item.horaInicio,
                            horaFim: item.horaFim,
                            descr_event: item.descr_event,
                            tipo: "Todos"
                        }
                    )

                })
                console.log(arrayTotal)
                setDadosTemp(arrayTotal);
                setAtualizadadostemp(!atualizadadostemp)
               // ListaDadosTemp(dadosTemp);

            })
            .catch((err) => {
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function ListarEvents() {

        api.get("/events_listar")
            .then((resp) => {
                //console.log(resp.data)
                let arraygeral = [];
                let arraycomemoracao = [];
                let arrayferias = [];
                let arrayconvencao = [];
                let arrayinalguracoes = [];

                let escolha = props.tipo;
                switch (escolha) {
                    case "geral":
                        resp.data.map((item) => {
                            let tipo = item.tipo_event
                            if (tipo === "eventos") {
                                arraygeral.push(
                                    {
                                        id_event: item.id_event,
                                        title: item.title,
                                        date: item.date,
                                        start: item.start,
                                        end: item.end,
                                        color: item.color,
                                        horaIni: item.horaInicio,
                                        horaFim: item.horaFim,
                                        descr_event: item.descr_event,
                                        tipo: item.tipo_event
                                    }
                                )
                            }
                        })
                        //console.log(arraygeral)
                        setDados(arraygeral);
                        break;

                    case "datasComemorativas":
                        resp.data.map((item) => {
                            let tipo = item.tipo_event
                            if (tipo === "datasComemorativas") {
                                arraycomemoracao.push(
                                    {
                                        id_event: item.id_event,
                                        title: item.title,
                                        date: item.date,
                                        start: item.start,
                                        end: item.end,
                                        color: item.color,
                                        horaIni: item.horaInicio,
                                        horaFim: item.horaFim,
                                        descr_event: item.descr_event,
                                        tipo: item.tipo_event
                                    }
                                )
                            }
                        })
                        setDados(arraycomemoracao);
                        break;

                    case "ferias":
                        resp.data.map((item) => {
                            let tipo = item.tipo_event
                            if (tipo === "ferias") {
                                arrayferias.push(
                                    {
                                        id_event: item.id_event,
                                        title: item.title,
                                        date: item.date,
                                        start: item.start,
                                        end: item.end,
                                        color: item.color,
                                        horaIni: item.horaInicio,
                                        horaFim: item.horaFim,
                                        descr_event: item.descr_event,
                                        tipo: item.tipo_event
                                    }
                                )
                            }
                        })
                        setDados(arrayferias);
                        break;

                    case "convencao":
                        resp.data.map((item) => {
                            let tipo = item.tipo_event
                            if (tipo === "convencao") {
                                arrayconvencao.push(
                                    {
                                        id_event: item.id_event,
                                        title: item.title,
                                        date: item.date,
                                        start: item.start,
                                        end: item.end,
                                        color: item.color,
                                        horaIni: item.horaInicio,
                                        horaFim: item.horaFim,
                                        descr_event: item.descr_event,
                                        tipo: item.tipo_event
                                    }
                                )
                            }
                        })
                        setDados(arrayconvencao);
                        break;

                    case "inalguracoes":
                        resp.data.map((item) => {
                            let tipo = item.tipo_event
                            if (tipo === "inalguracoes") {
                                arrayinalguracoes.push(
                                    {
                                        id_event: item.id_event,
                                        title: item.title,
                                        date: item.date,
                                        start: item.start,
                                        end: item.end,
                                        color: item.color,
                                        horaIni: item.horaInicio,
                                        horaFim: item.horaFim,
                                        descr_event: item.descr_event,
                                        tipo: item.tipo_event
                                    }
                                )
                            }
                        })

                        setDados(arrayinalguracoes);
                        break;
                }
                //console.log(arraynovo)           
            })
            .catch((err) => {
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    //------------------------------------------------pega evento------------------------------------------------//
    function pegaEvento(info) { //pega o evento e carrega os dados com base no bd

        //setTitle(info.event.title)  // info é padrao do fullcalender
        setId_event(info.event._def.extendedProps.id_event)
        localStorage.setItem('id_event', info.event._def.extendedProps.id_event)

        let id = localStorage.getItem('id_event');
        api.get("/eventos_id/" + id)
            .then((resp) => {
                setTitle(resp.data.title);
                setDescr_event(resp.data.descr_event);
                setHoraInicio(resp.data.horaInicio);
                setStart(resp.data.start);
                setEnd(resp.data.end);
                setColor(resp.data.color);
                setHoraFim(resp.data.horaFim);
                setTipo(resp.data.tipo_event);
            })
            .catch((err) => {
                alert("Erro ao carregar dados");
                console.log(err);
            });
        //console.log(info)
        //console.log(info.event.backgroundColor)
        //setHoraInicio(info.event._def.extendedProps.horaIni)
        // console.log(info.event._def.extendedProps.horaFim)
        // console.log(info.event._def.extendedProps.id_event)
        // console.log(info.event.title)
        // console.log(info.event.start)
        // console.log(info.event.end)

        var modal = new window.bootstrap.Modal(document.getElementById('ModalCalenderEdit'));
        modal.show();
    }
    //-------------------------------------------------pega evento fim -----------------------------------------------//

    function IncluirEvento() {

        const params = {
            title,
            date: start,
            start: start,
            end: end,
            tipo_event: tipo,
            descr_event,
            color,
            horaInicio,
            horaFim,
            status_event: "A"
        };

        api.post("/inserir_evento", params)
            .then((resp) => {
                if (resp.status === 201) {
                    alert("Incluido com sucesso");
                    ListarEvents();
                } else {
                    alert("Erro ao inserir dados");
                }
            })
            .catch((err) => {
                alert("Erro ao inserir dados");
            });
        // }
        limpaEventos();
    }

    function ExcluirEvento() {
        alert(id_event)
        let status_event = "I";
        const params = {
            status_event,
        };
        api.put("/evento_edit_status/" + id_event, params)
            .then((resp) => {
                if (resp.status === 200) {
                    alert("Registro excluído com sucesso!");
                    ListarEvents();
                } else {
                    alert("Erro ao excluir evento");
                }
            })
            .catch((err) => {
                alert("Erro ao editar dados");
            });
        limpaEventos();
    }

    const handleDateSelect = (info) => { // pega o dia que foi clicado no numero

        limpaEventos();
        // let dt1 = info.startStr.split("-")[0]
        // let dt2 = info.startStr.split("-")[1]
        // let dt3 = info.startStr.split("-")[2]
        //alert(dt3 + "/"+dt2+ "/"+dt1)
        // setStart(dt3 + "/" + dt2 + "/" + dt1);
        setTipo("eventos");
        setColor("#0000FF")
        var modal = new window.bootstrap.Modal(document.getElementById('ModalCalenderAdd'));
        modal.show();
    };

    function limpaEventos() {
        setTitle("");
        setDate("");
        setStart("");
        setEnd("");
        setColor("");
        setDescr_event("");
        setHoraInicio("");
        setHoraFim("");
    }

    function editEvento() {

        const params = {
            title,
            date: start,
            start: start,
            end: end,
            tipo_event: tipo,
            descr_event,
            color,
            horaInicio,
            horaFim
        };

        api.put("/event_edit/" + id_event, params)
            .then((resp) => {
                if (resp.status === 200) {
                    alert("Registro editado com sucesso!");
                    ListarEvents();
                } else {
                    alert("Erro ao editar dados");
                }
            })
            .catch((err) => {
                alert("Erro ao editar dados");
            });
        limpaEventos();
    }


    function ListaDados() { //lista dados para popular a lista de eventos suspensa
        let mespesq = mes.split("-"); //mes foi o escolhido no select mes
        let anopesquisa = mespesq[0]
        let mespesquisa = mespesq[1]

        let arrayMes = [];


        dados.map((item) => {
            let datas = item.date.split("-");
            let mesCad = datas[1]
            let anoCad = datas[0]

            const dataInvertida = item.date.split('-').reverse().join('/');
            const dataStart = item.start.split('-').reverse().join('/');
            const dataFim1 = item.end.split(" ")
            const dataFim = dataFim1[0].split('-').reverse().join('/');

            if (mespesquisa === mesCad && anopesquisa === anoCad) {
                arrayMes.push(
                    {
                        id_event: item.id_event,
                        titulo: item.title,
                        dataIni: dataInvertida,
                        dataStart: dataStart,
                        dataFim: dataFim,
                        horaIni: item.horaIni,
                        horaFim: item.horaFim,
                        descr: item.descr_event,
                        tipo:item.tipo
                    })
            }
        })
        // console.log(arrayMes)
        setDadosMes(arrayMes);
    }

    function ListaDadosTemp() { //lista dados para popular a lista de eventos suspensa
      
        let mespesq = mes.split("-"); //mes foi o escolhido no select mes
        let anopesquisa = mespesq[0]
        let mespesquisa = mespesq[1]

        let arrayMes = [];


        dadosTemp.map((item) => {
            let datas = item.date.split("-");
            let mesCad = datas[1]
            let anoCad = datas[0]

            const dataInvertida = item.date.split('-').reverse().join('/');
            const dataStart = item.start.split('-').reverse().join('/');
            const dataFim1 = item.end.split(" ")
            const dataFim = dataFim1[0].split('-').reverse().join('/');

            if (mespesquisa === mesCad && anopesquisa === anoCad) {
                arrayMes.push(
                    {
                        id_event: item.id_event,
                        titulo: item.title,
                        dataIni: dataInvertida,
                        dataStart: dataStart,
                        dataFim: dataFim,
                        horaIni: item.horaIni,
                        horaFim: item.horaFim,
                        descr: item.descr_event,
                        tipo: item.tipo
                    })
            }
        })
        // console.log(arrayMes)
     
        setDadosMes(arrayMes);
    }

    function ChangeMes(e) {
        //alert(e.target.value)
        localStorage.setItem('mes_calendario', e.target.value)
        setMes(e.target.value);

    }

    function limpaDadosMes() {
        setDadosMes([])
    }

    useEffect(() => {
        ListarEvents();
    }, []);

    useEffect(() => {
        ListaDadosTemp();
    }, [atualizadadostemp]);



    /*
    const dados=[
        { title: 'Convenção Casa Lúdica', date: '2024-09-15', color:'#FF4500', start:'2024-09-15 02:30:00', end: '2024-09-17 05:30:00'},
        { title: 'Lançamento', date: '2024-09-15', color:'#FFD700' },
        { title: 'Férias', date: '2024-09-17' }
    ]
    */
    return (<>
        <div className="col-6 container-calend">
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                locale={'pt-br'}
                events={dados}
                eventClick={pegaEvento}
                selectable={true}            // Permite a seleção de datas (necessário para capturar o clique)
                select={handleDateSelect}
            />
        </div>

        <div className="modal fade" id="ModalLista" tabindex="-1" aria-labelledby="ModalLista" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header">
                        <div className='d-flex'>
                            <h1 className="modal-title fs-5" id="ModalLista">Eventos do mês</h1>
                            <input className='ms-4' type="month" onChange={ChangeMes} value={mes} />
                        </div>

                        <button onClick={limpaDadosMes} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        <div className='d-flex list-group col-12'>
                            <div className='border border-dark-subtl p-4 rounded'>
                                <div className='col-12'>
                                    {
                                        dadosMes.map((item) => {
                                            return <ul clasNames="list-group justify-content-between col-10 ">
                                                <li key={item.id_event} className="list-group-item d-flex justify-content-between mb-1 linha-lista">
                                                    {item.titulo}
                                                    {item.horaIni && item.horaFim ?
                                                        <span className="badge text-bg-secondary rounded-pill ">{item.dataIni} - {item.horaIni} às {item.horaFim}</span> :
                                                        <span className="badge text-bg-secondary rounded-pill "> {item.dataIni} - {item.horaIni}</span>
                                                    }
                                                    {
                                                        item.dataIni !== item.dataFim ? <>
                                                            <span className="badge text-bg-primary rounded-pill align-middle">{item.dataFim} </span> </> : null
                                                    }
                                                </li>
                                            </ul>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                       
                        <button onClick={ListarTodosEventos} type="button" className="btn btn-primary" >Todos</button>
                        <button onClick={(e) => calendarioPDF(dadosMes)} type="button" className="btn btn-danger" disabled={dadosMes.length<=0} ><i className="bi bi-file-earmark-pdf "></i>Imprimir</button>
                        <button onClick={ListaDados} type="button" className="btn btn-primary" >Buscar</button>
                        <button type="button" onClick={limpaDadosMes} className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="ModalCalenderAdd" tabindex="-1" aria-labelledby="ModalCalenderAdd" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="ModalCalenderAdd">Novo Evento</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <dl className="row">
                            <dt className="col-sm-3">Título:</dt>
                            <dd className="col-sm-9" type="input"> <input type="text" className="form-control " onChange={(e) => setTitle(e.target.value)} value={title} /></dd>
                            <dt className="col-sm-3">Descrição:</dt>
                            <dd className="col-sm-9"><textarea type="text" className="form-control " onChange={(e) => setDescr_event(e.target.value)} value={descr_event}></textarea> </dd>
                        </dl>

                        <div className="row d-flex">
                            <div className='col-6'>
                                <small className="col-sm-6">Data início:</small>
                                <input type="date" className="form-control" onChange={(e) => setStart(e.target.value)} value={start} />
                            </div>

                            <div className='col-3'>
                                <small className="col-sm-6">Hora início:</small>
                                <input type="time" className="form-control" onChange={(e) => setHoraInicio(e.target.value)} value={horaInicio} />
                            </div>

                        </div>
                        <div className="row d-flex">
                            <div className='col-6'>
                                <small className="col-sm-6">Data fim:</small>
                                <input type="date" className="form-control" onChange={(e) => setEnd(e.target.value)} value={end} />
                            </div>

                            <div className='col-3'>
                                <small className="col-sm-6">Hora fim:</small>
                                <input type="time" className="form-control" onChange={(e) => setHoraFim(e.target.value)} value={horaFim} />
                            </div>

                        </div>
                        <div className="row d-flex">
                            <div className='col-6'>
                                <small className="col-sm-6">Tipo evento:</small>
                                <select type="text" className="form-control " onChange={(e) => setTipo(e.target.value)} value={tipo} >
                                    <option value="eventos">Eventos</option>
                                    <option value="datasComemorativas">Datas comemorativas</option>
                                    <option value="ferias">Férias</option>
                                    <option value="inalguracoes">Inalgurações</option>
                                    <option value="convencao">Convenção</option>
                                </select>
                            </div>

                            <div className='col-3'>
                                <small className="col-sm-6">Cor:</small>
                                <input type="color" className="form-control" onChange={(e) => setColor(e.target.value)} value={color} />
                            </div>
                        </div>
                    </div>
                    {
                        usuario === "ariscoz@gmail.com" ?
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={IncluirEvento} disabled={!title || !start || !descr_event}>Incluir</button>
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            </div> : null
                    }
                </div>
            </div>
        </div>

        <div className="modal fade" id="ModalCalenderEdit" tabindex="-1" aria-labelledby="ModalCalenderEdit" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="ModalCalenderEdit">Detalhes do Evento</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        <dl className="row">
                            <dt className="col-sm-3">Título:</dt>
                            <dd className="col-sm-9" type="input"> <input type="text" className="form-control " onChange={(e) => setTitle(e.target.value)} value={title} /></dd>
                            <dt className="col-sm-3">Descrição:</dt>
                            <dd className="col-sm-9"><textarea type="text" className="form-control " onChange={(e) => setDescr_event(e.target.value)} value={descr_event}></textarea> </dd>
                        </dl>

                        <div className="row d-flex">
                            <div className='col-6'>
                                <small className="col-sm-6">Data início:</small>
                                <input type="date" className="form-control" onChange={(e) => setStart(e.target.value)} value={start} />
                            </div>

                            <div className='col-3'>
                                <small className="col-sm-6">Hora início:</small>
                                <input type="time" className="form-control" onChange={(e) => setHoraInicio(e.target.value)} value={horaInicio} />
                            </div>

                        </div>
                        <div className="row d-flex">
                            <div className='col-6'>
                                <small className="col-sm-6">Data fim:</small>
                                <input type="date" className="form-control" onChange={(e) => setEnd(e.target.value)} value={end} />
                            </div>

                            <div className='col-3'>
                                <small className="col-sm-6">Hora fim:</small>
                                <input type="time" className="form-control" onChange={(e) => setHoraFim(e.target.value)} value={horaFim} />
                            </div>

                        </div>
                        <div className="row d-flex">
                            <div className='col-6'>
                                <small className="col-sm-6">Tipo evento:</small>
                                <select type="text" className="form-control " onChange={(e) => setTipo(e.target.value)} value={tipo} >
                                    <option value="eventos">Eventos</option>
                                    <option value="datasComemorativas">Datas comemorativas</option>
                                    <option value="ferias">Férias</option>
                                    <option value="inalguracoes">Inalgurações</option>
                                    <option value="convencao">Convenção</option>
                                </select>
                            </div>

                            <div className='col-3'>
                                <small className="col-sm-6">Cor:</small>
                                <input type="color" className="form-control" onChange={(e) => setColor(e.target.value)} value={color} />
                            </div>
                        </div>
                    </div>

                    {
                        usuario === "ariscoz@gmail.com" ?

                            <div className="modal-footer">
                                <div className='d-flex justify-content-between'>
                                    <div className='ms-e'>
                                        <button type="button" className="btn btn-danger me-5" onClick={ExcluirEvento} disabled={!title || !start || !descr_event} >Excluir</button>
                                        <button type="button" className="btn btn-danger ms-5 me-5" onClick={editEvento} disabled={!title || !start || !descr_event}>Salvar</button>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-success ms-5 me-3" data-bs-dismiss="modal">Cancelar</button>
                                    </div>
                                </div>
                            </div> : null
                    }
                </div>
            </div>
        </div>
    </>
    )
}