import { useNavigate } from "react-router-dom";
import logo2 from "../../assets/marca.jpeg";
import "../login/login.css"
import { useEffect, useState } from "react";
import { dadosLogin } from "../../zustand/zuztand";

import moment from 'moment';
import api from "../../services/api.js";

import { auth } from "../../services/firebaseConfig";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";


function Login() {

	const [logado, setLogado]= useState(false)

	function ListarColaboradores() { // para verificar os aniversários

		const dataAtual = moment().format('DD-MM-YYYY');
		let diaAtu = dataAtual.substring(0, 2);
		let mesAtu = dataAtual.substring(3, 5);

		let diaAni = ""
		let mesAni = ""
		let conta = 0;
		let arrayCol = []

		api.get("/col_aniver_listar")
			.then((resp) => {
				// console.log(resp.data)
				resp.data.map((item) => {
					var dat = item.data_nasc_col;
					diaAni = dat.substring(0, 2);
					mesAni = dat.substring(3, 5);

					if (diaAni === diaAtu && mesAni === mesAtu) {
						conta = conta + 1;
						arrayCol.push({
							id_colab_fran: item.id_colab_fran,
							nome_colaborador: item.nome_colaborador,
							data_nasc_col: item.data_nasc_col,
						})
					}
				})			
			})
			.catch((err) => {
				//setDadosCol([]);
				//  alert("Erro ao carregar dados");
			});
	}


	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [sucesso, setSucesso] = useState("");
	const navigate = useNavigate();
	const { changeUser, changeIfLogado } = dadosLogin();

	const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(auth);

	function Logar(e) {
		e.preventDefault();
		signInWithEmailAndPassword(email, password).then(resp => {
			//console.log(resp)
			if (resp) {
				let logou = []
				logou = resp
				//console.log(logou._tokenResponse.idToken)
				//console.log(logou.user.email)
				//dadosLogou.push(logou.user.email);				
				//localStorage.setItem('user_email', email)			
				//let userEmail = logou.user.email;
				//console.log(email)
				changeIfLogado(email);
				changeUser(email);
				navigate('/dashboard');
			} else {
				//alert(" Usuário ou senha inválida");
				setLogado(!logado)

				function horarioAtual() {
					setLogado(false)
			
				  }
				  setTimeout(horarioAtual, 2000); //método executa a função após três segundos

			}
		})

	}
	useEffect(() => {
		ListarColaboradores();

	}, []);
	return <>

		<div className="limiter ">

			<div className="container-login100 fund-login ">
				<div className="wrap-login100 ">
					<div className="login100-pic js-tilt ">
						<span className="fs-5 d-none d-sm-inline">
							<img src={logo2} className="img-logo" />
						</span>
					</div>

					<div className="login100-form validate-form">


						<div className="wrap-input100 validate-input" >
							<span className="texto_input">Email/Usuário</span>
							<input className="input100" type="text" name="user" onChange={(e) => setEmail(e.target.value)} />									
							
						</div>

						<div className="wrap-input100 validate-input" >
							<span className="texto_input">Senha</span>
							<input className="input100 input-user" type="password" name="pass"  onChange={(e) => setPassword(e.target.value)} />
												</div>
						{
							logado ?
								<div class="alert alert-danger w-20" role="alert">
									Usuário ou senha inválidos!
								</div> : null
						}
						<div className="container-login100-form-btn">
							<button className="login100-form-btn btn-enter" onClick={Logar}>Entrar</button>
						</div>
					</div>
				</div>
			</div>
		</div>

	</>
}

export default Login;