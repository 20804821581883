import { Dock } from "react-dock";
import { useEffect, useState } from "react";
import "./franquias-cad.css";
import api from "../../services/api.js";
import { Link } from "react-router-dom";

import generatePDF, { Margin } from 'react-to-pdf';
import { dadosLogin } from "../../zustand/zuztand.js"


function FranquiasCad(props) {
    const [show, setShow] = useState(false);
    const [operacao, setOperacao] = useState("");
    const [base64Image, setBase64Image] = useState("");
    const [nome_franquia, setNome_franquia] = useState("");
    const [razao_franquia, setRazao_franquia] = useState("");
    const [tel_franquia, setTel_franquia] = useState("");
    const [regiao, setRegiao] = useState("");
    const [end_franquia, setEnd_franquia] = useState("");
    const [data_inalg, setData_inalg] = useState("");
    const [cnpj_franquia, setCnpj_franquia] = useState("");
    const [id_franquia, setId_franquia] = useState(0);
    const [cep_franquia, setCep_franquia] = useState("");
    const [bairro_franquia, setBairro_franquia] = useState("");
    const [uf_franquia, setUf_franquia] = useState("");
    const [cidade_franquia, setCidade_franquia] = useState("");
    const [comp_franquia, setComp_franquia] = useState("");
    const [num_franquia, setNum_franquia] = useState("");
    const [obs_franquia, setObs_franquia] = useState("");
    const [data_encerramento, setData_encerramento] = useState("");
    const [motivo_franquia, setMotivo_franquia] = useState("");
    const [nome_franqueados, setNome_franqueados] = useState("");
    const [tipo_franquia, setTipo_franquia] = useState("Selecione");
    const [ie_franquia, setIe_franquia] = useState("");
    const [im_franquia, setIm_franquia] = useState("");
    const [data_contrato, setData_contrato] = useState("");
    const [cidades_abrang, setCidades_abrang] = useState("");
    const [data_aniver_cidade, setData_aniver_cidade] = useState("");

    const [cidades_abrang_cad, setCidades_abrang_cad] = useState([]);

    const { usuario } = dadosLogin()

    const personalizacao = { //para impressão pdf
        // Baixar/Salvar = save / Abrir no navegador = open
        method: 'open',
        page: {
            // Definir a margem: SMALL ou MEDIUM 
            margin: Margin.SMALL,
            // Formato da página: A4 ou letter
            format: 'A4',
            // Orientação do arquivo: portrait ou landscape
            orientation: 'portrait',
        },
    }

    const recuperarConteudoParaPDF = () => document.getElementById('conteudo-pdf');

    function getCep() {
        const cepFran = cep_franquia.replace(/\D/g, '')
        if (cep_franquia.length !== 8) {
            alert("CEP inválido.");

        } else {
            fetch(`https://viacep.com.br/ws/${cep_franquia}/json/`).then(res => res.json()).then(data => {
                //  console.log(data);

                if (data.erro) {
                    alert("CEP não encontrado.")
                } else {
                    setEnd_franquia(data.logradouro);
                    // localStorage.setItem('Rua', data.logradouro);
                    setBairro_franquia(data.bairro);
                    // localStorage.setItem('Bairro', data.bairro);
                    setCidade_franquia(data.localidade);
                    //localStorage.setItem('Local', data.localidade);
                    // setCep(data.cep);
                    setUf_franquia(data.uf);
                }
            });
        }
    }

    function Limpar() {
        setNome_franquia("");
        setRazao_franquia("");
        setEnd_franquia("");
        setData_inalg("");
        setCnpj_franquia("");
        setTel_franquia("");
        setCep_franquia("");
        setBairro_franquia("");
        setUf_franquia("");
        setCidade_franquia("");
        setComp_franquia("");
        setNum_franquia("");
        setObs_franquia("");
        setData_encerramento("");
        setMotivo_franquia("");
        setNome_franqueados("");
        setTipo_franquia("");
        setIe_franquia("");
        setIm_franquia("");
        setCidades_abrang("");
        setData_contrato("");
        setData_aniver_cidade("");

    }

    function ChangeDataInalgFranquia(e) {
        let ano = e.target.value.substring(0, 4);
        let mes = e.target.value.substring(5, 7);
        let dia = e.target.value.substring(8, 11);
        setData_inalg(dia + '/' + mes + '/' + ano);
    }
    function ChangeDataAniverCidade(e) {
        let ano = e.target.value.substring(0, 4);
        let mes = e.target.value.substring(5, 7);
        let dia = e.target.value.substring(8, 11);
        setData_aniver_cidade(dia + '/' + mes + '/' + ano);
    }


    function SalvarDadosFranquia() {

        if (uf_franquia === "PR" || uf_franquia === "SC" || uf_franquia === "RS") {
            localStorage.setItem('regiao', 'Sul');

        } else if (uf_franquia === "SP" || uf_franquia === "RJ" || uf_franquia === "MG" || uf_franquia === "ES") {
            localStorage.setItem('regiao', 'Sudeste');

        } else if (uf_franquia === "MS" || uf_franquia === "MT" || uf_franquia === "GO" || uf_franquia === "DF") {
            localStorage.setItem('regiao', 'Centro-Oeste');

        } else if (uf_franquia === "MA" || uf_franquia === "PI" || uf_franquia === "CE" || uf_franquia === "RN" || uf_franquia === "PB" || uf_franquia === "PE" || uf_franquia === "AL" || uf_franquia === "SE" || uf_franquia === "BA") {
            localStorage.setItem('regiao', 'Nordeste');
        } else if (uf_franquia === "RO" || uf_franquia === "AC" || uf_franquia === "AM" || uf_franquia === "RR" || uf_franquia === "AP" || uf_franquia === "PA" || uf_franquia === "TO") {
            localStorage.setItem('regiao', 'Norte');
        }


        if (nome_franquia === "" || cnpj_franquia === "" || uf_franquia === "Selecione" || uf_franquia === "") {
            alert("Campo nome, CNPJ , e UF são obrigatórios")
        } else {

            let status_franquia = "A";
            let id_usuario = 1;
            let regiao2 = localStorage.getItem('regiao')

            const params = {
                nome_franquia,
                razao_franquia,
                regiao: regiao2,
                data_inalg,
                end_franquia,
                cnpj_franquia,
                tel_franquia,
                status_franquia,
                cep_franquia,
                num_franquia,
                cidade_franquia,
                bairro_franquia,
                comp_franquia,
                uf_franquia,
                obs_franquia,
                id_usuario,
                data_encerramento,
                motivo_franquia,
                nome_franqueados,
                tipo_franquia,
                ie_franquia,
                im_franquia,
                cidades_abrang,
                data_contrato,
                data_aniver_cidade
            };

            const params2 = {
                nome_franquia,
                razao_franquia,
                regiao: regiao2,
                data_inalg,
                end_franquia,
                cnpj_franquia,
                tel_franquia,
                cep_franquia,
                num_franquia,
                cidade_franquia,
                bairro_franquia,
                comp_franquia,
                uf_franquia,
                obs_franquia,
                data_encerramento,
                motivo_franquia,
                nome_franqueados,
                tipo_franquia,
                ie_franquia,
                im_franquia,
                cidades_abrang,
                data_contrato,
                data_aniver_cidade
            };

            if (operacao === "new") {
                // NOVO REGISTRO........................

                api.post("/franquias_insert", params)
                    .then((resp) => {

                        if (resp.status === 201) {
                            alert("Incluído com sucesso")
                            localStorage.setItem('uf_franquia', uf_franquia);
                            setShow(false);
                            props.onClose(); //para atualizar a tabela 
                        } else {
                            alert("Erro ao inserir dados franquia");
                        }
                    })
                    .catch((err) => {
                        //setDados([]);
                        alert("Erro ao inserir dados ");
                    });

            } else {

                if (nome_franquia === "" || razao_franquia === "" || cnpj_franquia === "" || uf_franquia === "Selecione" || tipo_franquia === "Selecione" || tipo_franquia === "") {
                    alert("Campo nome,  e CNPJ são obrigatórios, uf inválido")
                } else {

                    // EDITAR REGISTRO EXISTENTE........................
                    let id = parseInt(localStorage.getItem("id_franquia"))

                    api.put("/franquias_edit/" + id, params2)
                        .then((resp) => {
                            if (resp.status === 200) {
                                localStorage.setItem('uf_franquia', uf_franquia);
                                alert("Registro editado com sucesso!")
                                setShow(false);
                                props.onClose();

                            } else {
                                alert("Erro ao editar dados");
                            }
                        })
                        .catch((err) => {
                            alert("Erro ao editar dados");
                        });
                }
            }
        }
    }

    function ChangeUf(e) {
        setUf_franquia(e.target.value);
        localStorage.setItem('uf_franquia', e.target.value);

    }

    function PesquisarCidadeCadastrada() {

        let id_franquia = localStorage.getItem('id_franquia');

        api.get("/cidades_list", { params: { id_franquia } })
            .then((resp) => {
                setCidades_abrang_cad(resp.data);
            })
            .catch((err) => {
                setCidades_abrang_cad([]);
                alert("Erro ao carregar dados");
            });
    }


    useEffect(() => {

        window.addEventListener('openFranquias', (param) => {

            if (param.detail.operacao === "new") {
                Limpar();
                setUf_franquia("Selecione");
            } else {
                Limpar();
                setUf_franquia("Selecione");

                api.get("/franquias_id/" + param.detail.id_franquia)
                    .then((resp) => {

                        setId_franquia(resp.data.id_franquia);
                        setNome_franquia(resp.data.nome_franquia);
                        setRazao_franquia(resp.data.razao_franquia);
                        setEnd_franquia(resp.data.end_franquia);
                        setData_inalg(resp.data.data_inalg);
                        setCnpj_franquia(resp.data.cnpj_franquia);
                        setTel_franquia(resp.data.tel_franquia);
                        setCep_franquia(resp.data.cep_franquia);
                        setBairro_franquia(resp.data.bairro_franquia);
                        setUf_franquia(resp.data.uf_franquia);
                        localStorage.setItem('uf_franquia', resp.data.uf_franquia);
                        setCidade_franquia(resp.data.cidade_franquia);
                        setComp_franquia(resp.data.comp_franquia);
                        setNum_franquia(resp.data.num_franquia);
                        setObs_franquia(resp.data.obs_franquia);
                        setData_encerramento(resp.data.data_encerramento);
                        setMotivo_franquia(resp.data.motivo_franquia);
                        setNome_franqueados(resp.data.nome_franqueados);
                        setTipo_franquia(resp.data.tipo_franquia);
                        setIe_franquia(resp.data.ie_franquia);
                        setIm_franquia(resp.data.im_franquia);
                        setCidades_abrang(resp.data.cidades_abrang);
                        setData_contrato(resp.data.data_contrato);
                        setData_aniver_cidade(resp.data.data_aniver_cidade);

                    })
                    .catch((err) => {
                        alert("Erro ao carregar dados");
                        console.log(err);
                    });
            }

            PesquisarCidadeCadastrada(); //atualiza o imput com as cidades escolhidas
            setShow(true);
            setOperacao(param.detail.operacao);
        });


    }, []);

    return <Dock position="right"
        isVisible={show}
        fluid={false}
        size={1200}
        onVisibleChange={(visible) => {
            setShow(visible);
        }}
    >
        <div className="container-fluid fundo_cad" id="conteudo-pdf">
            <h4 className="d-inline "> {operacao === "edit" ? "Editar Franquia " : "Nova Franquia"} </h4>

            <div className="row d-flex ">


                <div className="col-4 mt-4 ">
                    <small className="text-secondary">CNPJ</small>
                    <input type="text" className="form-control " onChange={(e) => setCnpj_franquia(e.target.value)} value={cnpj_franquia} />
                </div>

                <div className="col-4 mt-4">
                    <small className="text-secondary">I E</small>
                    <input type="text" className="form-control " onChange={(e) => setIe_franquia(e.target.value)} value={ie_franquia} />
                </div>
                <div className="col-2 mt-4 ms-5">
                    <small className="text-secondary">Data Inalguração</small>
                    <div className="input-group mb-3 blocoIcones_fran ">
                        <input type="text" className="form-control  " id="birth" value={data_inalg} />
                        <input type="date" className=" form-control  dt_inalg-btn" onChange={ChangeDataInalgFranquia} />
                    </div>
                </div>

            </div>
            <div className="row d-flex mt-2">

                <div className="col-4 ">
                    <small className="text-secondary">I M.</small>
                    <input type="text" className="form-control " onChange={(e) => setIm_franquia(e.target.value)} value={im_franquia} />
                </div>

                <div className="col-4 ">

                    <small className="text-secondary">Modalidade</small>
                    <select type="text" className="form-control " onChange={(e) => setTipo_franquia(e.target.value)} value={tipo_franquia} >
                        <option>Selecione</option>
                        <option>Premium</option>
                        <option>Quiosque</option>
                        <option>Grande</option>
                    </select>
                </div>
                <div className="col-2 ms-5">
                    <small className="text-secondary ">Tel.</small>
                    <input type="text" className="form-control tel-fran" onChange={(e) => setTel_franquia(e.target.value)} value={tel_franquia} />
                </div>
            </div>

            <div className="row d-flex mt-2">
                <div className="col-9">
                    <small className="text-secondary">Razão Social</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setRazao_franquia(e.target.value)} value={razao_franquia} />
                </div>
            </div>

            <div className="row d-flex justify-content-between mt-2">
                <div className="col-9">
                    <small className="text-secondary">Nome Fantasia</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setNome_franquia(e.target.value)} value={nome_franquia} />
                </div>
                <div className="col-2">
                    <div className="col-2">
                        <small className="text-secondary">CEP:</small>
                        <div className="col-11 blocoIconesCep-cad ">
                            <input type="text" className="form-control input-cep-cr  " onChange={(e) => setCep_franquia(e.target.value)} value={cep_franquia} />
                            <button onClick={getCep}><i className=" bi bi-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row d-flex mt-2">
                <div className="col-9">
                    <small className="text-secondary">Rua/Av.</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setEnd_franquia(e.target.value)} value={end_franquia} />
                </div>

                <div className="col-1 ms-5">
                    <div className="col-12">
                        <small className="text-secondary">Número</small>
                        <input type="text" className="form-control " onChange={(e) => setNum_franquia(e.target.value)} value={num_franquia} />
                    </div>
                </div>
                <div className="col-1 ms-4">
                    <div className="col-12">
                        <small className="text-secondary">UF</small>
                        <select type="text" className="form-control " onChange={ChangeUf} value={uf_franquia} >
                            <option value="">Selecione</option>
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="DF">DF</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MS">MS</option>
                            <option value="MT">MT</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PR">PR</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row d-flex mt-2 ">
                <div className="col-9 ">
                    <small className="text-secondary">Bairro</small>
                    <input type="text" className="form-control " onChange={(e) => setBairro_franquia(e.target.value)} value={bairro_franquia} />
                </div>
            </div>

            <div className="row d-flex mt-2 ">
                <div className="col-6">
                    <small className="text-secondary">Cidade</small>
                    <input type="text" className="form-control " onChange={(e) => setCidade_franquia(e.target.value)} value={cidade_franquia} />
                </div>
                <div className="col-6">
                    <small className="text-secondary">Complemento</small>
                    <input type="text" className="form-control " onChange={(e) => setComp_franquia(e.target.value)} value={comp_franquia} />
                </div>
            </div>

            <div className="row d-flex ">
                <div className="col-10 ">
                    <small className="text-secondary">Franqueados:</small>
                    <input type="text" className="form-control " onChange={(e) => setNome_franqueados(e.target.value)} value={nome_franqueados} />
                </div>
            </div>
            <div className="row d-flex mt-2 ">
                <div className="col-10">
                    <small className="text-secondary">Obs Franquia:</small>
                    <input type="text" className="form-control nome-cr" onChange={(e) => setObs_franquia(e.target.value)} value={obs_franquia} />
                </div>
            </div>
            <div className="row d-flex mt-2 ">
                <div className="col-2  ">
                    <small className="text-secondary">Data Contrato</small>
                    <input type="text" className="form-control cpf " onChange={(e) => setData_contrato(e.target.value)} value={data_contrato} />
                </div>

                <div className="col-6 ">
                    <div className="d-flex">
                        <div className="col-12 ">
                            <small className="text-secondary">Cidades de Abrangência:</small>
                            <input type="text" className="form-control " onChange={(e) => setCidades_abrang(e.target.value)} value={cidades_abrang} />
                        </div>
                    </div>
                </div>
                <div className="col-2 ">
                    <small className="text-secondary">Data Aniver. Cidade</small>
                    <div className="input-group mb-3 blocoIcones_fran ">
                        <input type="text" className="form-control  " id="birth" value={data_aniver_cidade} />
                        <input type="date" className=" form-control  dt_inalg-btn" onChange={ChangeDataAniverCidade} />
                    </div>
                </div>
            </div>

            <div className="row d-flex mt-2">
                <div className="col-2">
                    <small className="text-secondary">Data Encerramento</small>
                    <input type="text" className="form-control " onChange={(e) => setData_encerramento(e.target.value)} value={data_encerramento} />
                </div>

                <div className="col-8 ">
                    <small className="text-secondary">Motivo</small>
                    <input type="text" className="form-control " onChange={(e) => setMotivo_franquia(e.target.value)} value={motivo_franquia} />
                </div>
            </div>

        </div>
        <div className="d-flex justify-content-center fundo_cad ">

            <div className="row d-flex div-btn-franquias">
                <div className="text-center mb-5">
                    <button onClick={() => generatePDF(recuperarConteudoParaPDF, personalizacao)} className="btn btn-danger btn-fran-imprimir ms-4 "><i className="bi bi-file-earmark-pdf me-2"></i>Imprimir</button>
                    <button className="btn btn-outline-primary me-3 ms-3 " onClick={() => setShow(false)}>Cancelar</button>
                    {
                        usuario === "ariscoz@gmail.com" ?
                            <button className="btn btn-primary " onClick={SalvarDadosFranquia} >Salvar Dados</button> : null
                    }

                </div>
            </div>


        </div>





    </Dock>
}

export default FranquiasCad;