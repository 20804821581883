import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

import FranqueadosCad from "../franqueados-cad/franqueados-cad.jsx";
import api from "../../services/api.js";
import "../franqueados/franqueados.css";
import franqueadosPDF from "../../report/report.franqueados/report.franqueados.jsx";
import Navbar from "../../components/navbar/navbar.jsx";
import { dadosLogin } from "../../zustand/zuztand";

function Franqueados() {

    const [qtd_reg_pagina, setQtdRegPagina] = useState(10);
    const [dados, setDados] = useState([]);
    const [total_registros, setTotalRegistros] = useState(0);
    const [regiao, setRegiao] = useState("");
    const navigate = useNavigate();

    const [busca, setBusca] = useState("");
    const [buscaCpf, setBuscaCpf] = useState("");
    const { ifLogado, usuario } = dadosLogin()

    const columns = [
        {
            name: 'Código',
            selector: row => ("0000" + row.id_franqueado).slice(-4), //para colocar zero a esquerda
            sortable: true,
            width: "9%",
        },
        {
            name: 'Nome Franqueado',
            selector: row => row.nome_franqueado,
            sortable: true,
            width: "40%",
            compact: true
        },
        {
            name: 'Franquias',
            selector: row => row.nome_franquias,

            sortable: true,
            width: "0%",
            compact: true
        },
        {
            name: 'Tel.',
            selector: row => row.tel_franqueado,
            sortable: true,
            width: "15%",
            right: true
        },
        {
            name: 'CPF',
            selector: row => row.cpf_franqueado,
            sortable: true,
            width: "15%",
            right: true
        },
        {
            cell: (row) => <>
                <Link to="/franqueados-docs" onClick={() => Pegarid_franqueado(row.id_franqueado)} className="btn btn btn-info ms-3"> <i className="bi bi-filetype-doc"></i></Link>
                <button onClick={() => EditarFranqueado(row.id_franqueado)} className="btn btn-primary ms-3"><i className="bi bi-pencil-square"></i></button>
                {
                    usuario === "ariscoz@gmail.com" ?
                        <button onClick={() => AltStatusFranqueado(row.id_franqueado)} className="btn btn-danger ms-3"><i className="bi bi-trash3-fill"></i></button> : null
                }

            </>,
            width: "20%",
            right: true
        }
    ];

    const paginationOptions = {
        rowsPerPageText: 'Registros por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: false,
        selectAllRowsItemText: 'Todos'
    };

    function Pegarid_franqueado(id, nome) {
        localStorage.setItem('id_franqueado', id);
    }

    function NovoFranqueado() {
        const event = new CustomEvent("openFranqueados", {
            detail: {
                operacao: "new"
            }
        });

        window.dispatchEvent(event);
    }

    function EditarFranqueado(id) {
        localStorage.setItem('id_franqueado', id);

        const event = new CustomEvent("openFranqueados", {
            detail: {
                operacao: "edit",
                id_franqueado: id
            }
        });
        window.dispatchEvent(event);
    }


    function EditarFranqueadoSalvo() { //para abrir o ultimo visualizado
        var idf = localStorage.getItem('id_franqueado');

        const event = new CustomEvent("openFranqueados", {
            detail: {
                operacao: "edit",
                id_franqueado: idf
            }
        });
        window.dispatchEvent(event);
    }

    function Pegarid_franqueado(id) {
        localStorage.setItem('id_franqueado', id);

    }



    function ListarFranqueados(id_usuario, regiao, pagina, qtd_reg_pagina) {

        api.get("/franqueados_listar", { params: { id_usuario, regiao, pagina, qtd_reg_pagina } })
            .then((resp) => {

                setTotalRegistros(resp.data.total_registros);
                setDados(resp.data.dados);
            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFranqueadoNome() {

        api.get("/franqueados_busca/", { params: { busca } })
            .then((resp) => {
                setDados(resp.data);

            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }

    function PesquisarFranqueadoCpf() {

        api.get("/franqueados_busca_cpf/", { params: { buscaCpf } })
            .then((resp) => {
                setDados(resp.data);

            })
            .catch((err) => {
                setTotalRegistros(0);
                setDados([]);
                alert("Erro ao carregar dados");
            });
    }
    /*
        function PesquisarFranqueadoId() {
    
            let id_franqueado = localStorage.getItem('id_franqueado');
            alert(id_franqueado)
            setDados([]);
    
    
               api.get("/franqueados_id/" + id_franqueado)
                .then((resp) => {
    
                    setDados(resp.data);
                    console.log(resp.data)
    
                })
                .catch((err) => {
                    setTotalRegistros(0);
                    setDados([]);
                    alert("Erro ao carregar dados");
                });
        }
                */

    function AltStatusFranqueado(id) {
        let id_franqueado = id;

        const param2 = {
            status_franqueado: "I",
        }
        confirmAlert({
            title: 'Exclusão',
            message: 'Confirma exclusão do Franqueado?',
            buttons: [
                {
                    label: 'Sim',
                    onClick: () => {
                        api.put("/franqueados_edit_status/" + id_franqueado, param2)
                            .then((resp) => {
                                if (resp.status === 200) {

                                    ListarFranqueados(localStorage.getItem("id_usuario"), regiao, 1, qtd_reg_pagina);

                                } else {
                                    alert("Erro ao excluir dados");
                                }
                            })
                            .catch((err) => {
                                alert("Erro ao excluir dados");
                            });
                    }
                },
                {
                    label: 'Não',
                    onClick: () => { }
                }
            ]
        });
    }

    function AtualizaTab() {
        setBusca("");
        ListarFranqueados(localStorage.getItem("id_usuario"), regiao, 1, qtd_reg_pagina);

    }

    function ChangeRegiao(e) {
        setRegiao(e.target.value);
        ListarFranqueados(localStorage.getItem("id_usuario"), e.target.value, 1, qtd_reg_pagina);
    }

    function ChangePerRows(newQtd, page) {
        ListarFranqueados(localStorage.getItem("id_usuario"), regiao, page, newQtd);
        setQtdRegPagina(newQtd);
    }

    function ChangePage(page) {
        ListarFranqueados(localStorage.getItem("id_usuario"), regiao, page, qtd_reg_pagina);
    }

    function RefreshDados() {
        ListarFranqueados(localStorage.getItem("id_usuario"), regiao, 1, qtd_reg_pagina);
    }

    useEffect(() => {


        if (busca === "") {
            ListarFranqueados(localStorage.getItem("id_usuario"), regiao, 1, qtd_reg_pagina);

        } else {
            PesquisarFranqueadoNome();
        }

    }, [busca]);

    useEffect(() => {


        if (buscaCpf === "") {
            ListarFranqueados(localStorage.getItem("id_usuario"), regiao, 1, qtd_reg_pagina);

        } else {
            PesquisarFranqueadoCpf();
        }

    }, [buscaCpf]);

    useEffect(() => {
        localStorage.setItem("id_usuario", 1)

    }, []);

    return <>

        <FranqueadosCad onClose={RefreshDados} />
        <div className="d-flex justify-content-end">
            <Link to="/menu-franquias" className="btn btn-success  mt-2 me-5" >Voltar </Link>
        </div>

        <div className="container-fluid ">
            <div className="row flex-nowrapl me-5">
                <div className="col py-3 me-3">
                    <div className="input-group justify-content-end">
                        <input onChange={(e) => setBusca(e.target.value)} type="text" className="form-control buscainput" id="pesq" placeholder="Pesquisar franqueado" value={busca} />
                        <button onClick={AtualizaTab} className="btn btn-primary mb-2" type="button" id="button-addon2">Mostrar Todos</button>
                        <button onClick={EditarFranqueadoSalvo} className="btn btn-primary mb-2 bi bi-arrow-left-square ms-2" type="button" id="button-addon2 "></button>
                    </div>
                    <div className="bg-white p-4 rounded-4 div-btns">

                        <div className="d-flex mb-3  justify-content-end div-btns">
                            <div className="d-flex ">
                                <small className="text-secundary fw-bold mt-2 me-1 ms-4 me-2">CPF</small>
                                <input type="text" className="form-control cnpj" onChange={(e) => setBuscaCpf(e.target.value)} value={buscaCpf} placeholder="999.999.999-99" />
                            </div>
                            <div className="align-items-end">
                                <button onClick={(e) => franqueadosPDF(dados)} className="btn btn-danger btn-aluno-imprimir ms-4"><i className="bi bi-file-earmark-pdf me-2"></i>Imprimir</button>
                                {
                                    usuario === "ariscoz@gmail.com" ?
                                        <button onClick={NovoFranqueado} className="btn btn-primary btn-aluno-novo  ms-4"><i className="bi bi-plus-lg me-2"></i>Novo Franqueado</button> : null
                                }
                            </div>
                        </div>

                        <DataTable className="tabela-items_tabAlu" columns={columns}
                            data={dados}
                            pagination={true}
                            paginationComponentOptions={paginationOptions}
                            noDataComponent={<p className="no-data-found">Nenhum registro encontrado</p>}
                            paginationServer={true}
                            paginationTotalRows={total_registros}
                            onChangeRowsPerPage={ChangePerRows}
                            onChangePage={ChangePage}
                        />

                    </div>

                </div>
            </div>
        </div>

    </>
}
export default Franqueados;