import { Link } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/pt-br'
import api from "../../services/api.js";
import { useEffect, useState } from "react";

import "../aniversario-cidades/aniversario-cidades.css";

function AniversarioCidades() {
  const local = moment().locale('pt-br').format('LL'); //converte a data par pt-br
  const local_novo = local.slice(5); // separa o texto inicial ex 12 se setembro de 2024 ficando somente setembro...
  const [dados, setDados] = useState([]);


  var Xmas95 = new Date();
  var month = Xmas95.getMonth();
  var mesAtual = month + 1;

  function PesquisarAniverFranquias() {

    let novo_array = [];

    api.get("/franquia_busca")
      .then((resp) => {
        // console.log(resp.data)
        resp.data.map((item) => {
          let dtaAniver = parseInt(item.data_aniver_cidade.substring(3, 5));

          if (dtaAniver === mesAtual) {
            novo_array.push({
              nome: item.razao_franquia,        
              cid: item.cidade_franquia,
              img: item.img_cidade,         
              data: item.data_aniver_cidade  
            })
          }
        })
        // console.log(novo_array)
        setDados(novo_array);
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }


  useEffect(() => {
    PesquisarAniverFranquias();

  }, []);

  return <>
    <div className="d-flex container-fundo-cidades">
      <div className="d-block container-body-cidades">
        <div className="d-flex justify-content-between">
          <div className="col-1">
          </div>
          <h4 className="ms-3 mt-2">
            Cidades Aniversariantes: {local_novo}
          </h4>
          <div className="col-1 ">
            <Link to="/menu-aniversariantes" className="btn btn-success p-2 me-5 mt-2 btn-sm" >Voltar </Link>
          </div>
        </div>
        <div className="coluna-aniversariantes-cidades">

          {
            dados.map((item) => {
              return <div key={item.id_franquias} className="d-flex justify-content-center  ">
                <div className="col-4 ">
                  <div className="card mt-4 ms-3 " >
                    <img src={item.img} className="card-img-top img-card-cidades ms-3 mt-3" alt="..." />
                    <div className="card-body ms-4">
                      <h5 className="card-title">{item.cid}</h5>                     
                      <p className=" d-flex ">{item.data}</p>
                      <small className=" d-flex ">{item.nome}</small>
                    </div>
                  </div>
                  <div className="card mt-4 ms-4" >
                  </div>
                </div>
              </div>
            })

          }
        </div>
      </div>
    </div>
  </>
}
export default AniversarioCidades;