import { useEffect, useState } from "react";
import "../franquia-fotos-pesq/franquia-fotos-pesq.css"
import AutocompleteFranquia from "../../components/autocomplete-franquia/autocomplete-franquia.jsx";

import { Link, useNavigate } from "react-router-dom";

import { ref, uploadBytes, getDownloadURL, listAll, list, } from "firebase/storage";
import { storage } from "../../services/firebaseConfig.js";
import { dadosLogin } from "../../zustand/zuztand.js"

import api from "../../services/api.js";


function FranquiaFotoPesq() {

    const [imageUrls, setImageUrls] = useState([]);
    const [dadosUrl, setDadosUrl] = useState([]);
    const [url_imgagem_franquia, setUrl_imgagem_franquia] = useState("");
    const [pastaStorage, setPastaStorage] = useState("");
    const [atualiza, setAtualiza] = useState(false);
    const [id_pesq, setId_pesq] = useState(0);
    const navigate = useNavigate();
    const { ifLogado } = dadosLogin()


    const [codigo, setCodigo] = useState(0);
    const [razao_franquia, setRazao_franquia] = useState("");
    const [cidade_franquia, setCidade_franquia] = useState("");
    const [texto, setTexto] = useState("");
    const [escolha, setEscolha] = useState(true);
    const [url_doc_fornecedor, setUrl_doc_fornecedor] = useState("");
    const [dadosFranquiasCidades, setDadosFranquiasCidades] = useState([]);
    const [buscaAbrang, setBuscaAbrang] = useState("");



    function AtualiZaListaUrlIdDigitado() { //atualiza lista vindo do storage
        setImageUrls([])
        const imagesListRef3 = ref(storage, `franquias/arquivos/${localStorage.getItem('id_pesq')}/${pastaStorage}/`);  //pasta do storage que estao as imagens ex:fornecedores/arquivos/2/
        var dadosUrlDow = []
        listAll(imagesListRef3).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => [...prev, url]);
                    //console.log(url)
                    dadosUrlDow.push({
                        url
                    })
                });
            });
            //setImageUrls(dadosUrlDow)
            setDadosUrl(dadosUrlDow)
            //console.log(dadosUrl)
        });
    }
    useEffect(() => {

        const imagesListRef3 = ref(storage, `franquias/arquivos/${id_pesq}/${pastaStorage}/`);  //pasta do storage que estao as imagens ex:fornecedores/arquivos/2/
        var dadosUrlDow = []
        setImageUrls([])
        listAll(imagesListRef3).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => [...prev, url]);
                    //console.log(url)
                    dadosUrlDow.push({
                        url
                    })
                });
            });

            setDadosUrl(dadosUrlDow)
            setUrl_imgagem_franquia('')
        });
    }, [atualiza]);


    function PegarUrl_franquia(url) {
        setUrl_imgagem_franquia(url)
    }

    function ChangePastaStorage(e) {//para selecionar no combobox a pasta onde seará salvo ao arquivo no firebase
        setPastaStorage(e.target.value);
        setAtualiza(!atualiza)
    };


    function ChangeId_pesq(e) {
        setId_pesq(e.target.value);
        localStorage.setItem('id_pesq', e.target.value);
    }
    function usar() {
        setId_pesq(codigo)
        setAtualiza(!atualiza)
        setCidade_franquia("")
        setRazao_franquia("")
        setTexto("")
        ListarFranquiasCidade()
    }
    function Limpa_id() {
        setId_pesq(0)
    }


    function ListarFranquiasCidade() {//somente id e nome para montar combo
        setDadosFranquiasCidades([])
        api.get("/franquia_busca_abrang/", { params: { buscaAbrang } })
            .then((resp) => {        

                setDadosFranquiasCidades(resp.data);       
                            
            })
            .catch((err) => {
                setDadosFranquiasCidades([]);
                alert("Erro ao carregar dados");
            });
    }

    function ChangeId_franquia(e) {
        let yy = e.target.value
        let gg = yy.split('/')
        setCodigo(gg[0])
        setCidade_franquia(gg[1])
        setRazao_franquia(gg[2])
    }

    useEffect(() => {
        ListarFranquiasCidade();

    }, [buscaAbrang]);
    return <>

        {
            ifLogado != "notLogad" ? <>
                <div className="row mt-2 ms-2">
                    <div className="row mt-2 ">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex ">
                                <div className="col-3">
                                    <div className="input-group">
                                        <small className="text-white me-2 mt-2 ">Id</small>
                                        <input type="number" class="form-control input-number" onChange={ChangeId_pesq} value={id_pesq} />
                                        <span className="input-group-addon">
                                            <button className="bi bi-search btn-id text-white" data-bs-toggle="modal" data-bs-target="#ModalFranquias" onClick={Limpa_id}>
                                            </button>
                                        </span>
                                    </div>
                                </div>

                                <small className="text-white mt-2 ms-5 me-2">Pastas</small>
                                <div className="col-4">
                                    <select type="text" className="form-control" onChange={ChangePastaStorage}>
                                        <option value="">Selecione</option>
                                        <option value="Projeto">Projeto</option>
                                        <option value="Inalguração">Inalguração</option>
                                        <option value="Eventos">Eventos</option>
                                        <option value="Visitas">Visitas</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-1 ">
                                <Link to="/menu-franquias" className="btn btn-success ms-4 " >Voltar</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row d-flex ">
                    <div>
                        <div className="d-flex ms-5 mt-3 overflow-y-hidden">
                            <iframe className="ms-4 frame-pesq" src={url_imgagem_franquia}></iframe>
                            <div class="col-1 overflow-y-scroll barra-l ">
                                {imageUrls.map((url, index) => {
                                    return <>
                                        <div className="col-1 card ms-2 card-franquia mt-3" >
                                            <button onClick={() => PegarUrl_franquia(url)}><img className=" d-flex card-franquia me-2  " alt="Imagem" src={url} /></button>
                                        </div>
                                    </>
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                {/*----------------------------------------------- Modal Franquias Inicio -----------------------------------------*/}
                <div className="modal fade" id="ModalFranquias" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Franquias</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12 bg-white  rounded-4 " >
                                    <div className="container-fluid h-100 pt-4 sidebar p-4 ">

                                        <div className="d-flex mb-3">
                                            <div className="col-6 ">
                                                <input type="text" className="form-control" placeholder='Cidade....' value={buscaAbrang} onChange={(e) => setBuscaAbrang(e.target.value)} />
                                            </div>

                                            <div className="col-6 ms-2 ">
                                                <select className="form-control " name="url" id="url" onChange={ChangeId_franquia}>
                                                    <option value="">Selecione a cidade</option>
                                                    {
                                                        dadosFranquiasCidades.map((item) => {
                                                            return <option key={item.id_franquia} value={item.id_franquia + '/' + item.cidade_franquia + '/' + item.razao_franquia}>{item.cidade_franquia}</option>
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>





                                        <div className="col-8  ">
                                            < AutocompleteFranquia placeholder="Digite o nome da franquia..."
                                                onClickId={setCodigo} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                                                onClickNome={setRazao_franquia} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                                                onClickTexto={setTexto} // recebe da tela autocomplete para alterar os dados nesta tela produtos
                                                onClickUrl={setUrl_doc_fornecedor}
                                                onClickCidade={setCidade_franquia}
                                                value={razao_franquia}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="col-9">
                                            <h6>
                                                Nome: {razao_franquia}
                                            </h6>
                                            <small>
                                                Cidade: {cidade_franquia}
                                            </small>
                                        </div>

                                        <div className="col-1">
                                            <button onClick={usar} type="button" className="btn btn-primary btn-sm ms-3" data-bs-dismiss="modal">Usar</button>
                                        </div>

                                    </div>


                                    <div className="row  mt-3 ">
                                        <div className="bg-white p-4 rounded-4 border ">
                                            <div className="d-flex justify-content-end ">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                {/*----------------------------------------------- Modal Franquias Fim -----------------------------------------*/}

            </> : navigate('/login')
        }
    </>
}
export default FranquiaFotoPesq;