import Navbar from "../../components/navbar/navbar.jsx";
import { dadosLogin } from "../../zustand/zuztand";
import { useNavigate } from "react-router-dom";

import "../menucolaboradores/menu-colaboradores.css";
import { Link } from "react-router-dom";

function MenuColaboradores(props) {
    const navigate = useNavigate();
    const { ifLogado } = dadosLogin()

    return <>
        {
            ifLogado != "notLogad" ? <>
                <div className="d-flex container-fundo-menu-col">
                    <Navbar tela="colaboradores" />
                    <div className="row container-body-menu-col">
                        <img className="banner-colaboradores" src="https://firebasestorage.googleapis.com/v0/b/franqueadora-ce93d.appspot.com/o/banners%2Fcolaboradores.png?alt=media&token=16bc0454-e5c5-4554-84da-816552e2d2bc" alt="banner-colaboradores" />
                        <div className="conteudo fixed-bottom">
                            <div className="d-flex justify-content-center">
                                <div className="d-flex justify-content-between">
                                   
                                    <div className="col-2 mb-2  btn-orange-tam">
                                        <Link to="/colaboradores" className="btn-orange me-3 " aria-current="page">Dados Cadastrais</Link>
                                    </div>
                                    <div className="col-2 mb-2 ms-3 btn-orange-tam ">
                                        <Link to="/colaboradores-contratos" className="btn-orange me-3 " aria-current="page">Contrato</Link>
                                    </div>
                                    <div className="col-2 mb-2 ms-3 btn-orange-tam ">
                                        <Link to="/colaboradores-autorizacoes" className="btn-orange me-3 " aria-current="page">Autorizações</Link>
                                    </div>
                                    <div className="col-2 mb-2 ms-3 btn-orange-tam ">
                                        <button className="btn-orange me-3 ">Fotos</button>
                                    </div>
                                    <div className="col-1"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></> : navigate('/login')
        }
    </>
}
export default MenuColaboradores;