import api from "../../services/api.js";
import { useEffect, useState } from "react";
import "../fornecedrores-docs/fornecedores-docs.css"
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";


function FornecedoresDocs() {

  const [dados, setDados] = useState([]);
  const [id_fornecedor, setId_fornecedor] = useState(0);
  const [id_doc_fornecedor, setId_doc_fornecedor] = useState(0);
  const [url_doc_forn, setUrl_doc_forn] = useState("");
  const [descr_doc, setDescr_doc] = useState("");
  const [controle_doc_forn, setControle_doc_forn] = useState("");

  function PesquisarImgContrato() {

    let id_fornecedor = localStorage.getItem('id_fornecedor');

    api.get("/fornecedores_doc_url", { params: { id_fornecedor } })
      .then((resp) => {
        setDados(resp.data);
        // console.log(resp.data)
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }


  function ChangeUrl(e) {
    setUrl_doc_forn(e.target.value);

  }

  useEffect(() => {
    PesquisarImgContrato();

  }, []);


  return <>

    <div className="container-fluid pt-2 ">
      <div className=" d-flex justify-content-between">
        <h3 className="d-flex">Documentos Fornecedores</h3>
        <h4>
          Fornecedor: {localStorage.getItem('id_fornecedor')}
        </h4>
        <Link to="/menu-fornecedores" className="btn btn-outline-success p-2 botaoS mt-1 me-5" >Voltar </Link>
      </div>


      <div className="container-fluid pt-2 rounded-3 conteiner ">


        <div className="col-8 ms-4 mb-3">
          <select className="form-control " name="url" id="url" onChange={ChangeUrl}>
            <option value="">Selecione</option>
            {
              dados.map((item) => {
                return <option key={item.id_doc_fornecedor} value={item.url_doc_forn}>{item.descr_doc}</option>
              })
            }
          </select>
        </div>

        <div>

          <iframe className=" col-11 frame ms-4" src={url_doc_forn}></iframe>

        </div>

      </div>
    </div>
  </>
}
export default FornecedoresDocs;