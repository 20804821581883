import { useEffect, useState } from "react";
import "../franquia-fotos/franquia-fotos.css"

import { Link, useNavigate } from "react-router-dom";

import { ref, uploadBytes, getDownloadURL, listAll, list, } from "firebase/storage";
import { storage } from "../../services/firebaseConfig.js";
import { dadosLogin } from "../../zustand/zuztand.js"


function FranquiaFoto() {

    const [dados, setDados] = useState([]);
    const [id_fornecedor, setId_fornecedor] = useState(0);
    const [id_doc_fornecedor, setId_doc_fornecedor] = useState(0);
    const [url_doc_franq, setUrl_doc_franq] = useState("");
    const [descr_doc, setDescr_doc] = useState("");


    const [imageUpload, setImageUpload] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const [dadosUrl, setDadosUrl] = useState([]);
    const [url_imgagem_franquia, setUrl_imgagem_franquia] = useState("");
    const [pastaStorage, setPastaStorage] = useState("");
    const [atualiza, setAtualiza] = useState(false);
    const [id_pesq, setId_pesq] = useState(0);
    const navigate = useNavigate();
    const { ifLogado } = dadosLogin()

    /*function PesquisarImgContrato() {
  
      let id_fornecedor = localStorage.getItem('id_fornecedor');
  
      api.get("/fornecedores_doc_url", { params: { id_fornecedor } })
        .then((resp) => {
          setDados(resp.data);
          // console.log(resp.data)
        })
        .catch((err) => {
          setDados([]);
          alert("Erro ao carregar dados");
        });
    }
        */


    function ChangeUrl(e) {
        setUrl_doc_franq(e.target.value);

    }


    function AtualiZaListaUrl() { //atualiza lista vindo do storage

        var dadosUrlDow = []
        listAll(imagesListRef2).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => [...prev, url]);
                    //console.log(url)
                    dadosUrlDow.push({
                        url
                    })
                });
            });
            //setImageUrls(dadosUrlDow)
            setDadosUrl(dadosUrlDow)
            //console.log(dadosUrl)
        });
    }


    function AtualiZaListaUrlIdDigitado() { //atualiza lista vindo do storage
        setImageUrls([])
     const imagesListRef3= ref(storage, `franquias/arquivos/${localStorage.getItem('id_pesq')}/${pastaStorage}/`);  //pasta do storage que estao as imagens ex:fornecedores/arquivos/2/
        var dadosUrlDow = []
        listAll(imagesListRef3).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => [...prev, url]);
                    //console.log(url)
                    dadosUrlDow.push({
                        url
                    })
                });
            });
            //setImageUrls(dadosUrlDow)
            setDadosUrl(dadosUrlDow)
            //console.log(dadosUrl)
        });
    }
    useEffect(() => {

        var dadosUrlDow = []
        setImageUrls([])
        listAll(imagesListRef2).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageUrls((prev) => [...prev, url]);
                    //console.log(url)
                    dadosUrlDow.push({
                        url
                    })
                });
            });
            setDadosUrl(dadosUrlDow)
            setUrl_imgagem_franquia('')
        });
    }, [atualiza]);


    function PegaUrl() {
        var str = ""
        dadosUrl.map(function (item, indice) {
            str = JSON.stringify(item);
            if (str.match(/.pdf/)) {
                console.log('string encontrada');
            } else {
                console.log('não encontrada');
            }
        });
    }


    var dataAtua = new Date(); //para salvar as imagens com nomes diferentes com o getTime

    const imagesListRef2 = ref(storage, `franquias/arquivos/${localStorage.getItem('id_franquia')}/${pastaStorage}/`);  //pasta do storage que estao as imagens ex:fornecedores/arquivos/2/

    const uploadFile = () => {
        setImageUrls([])
        if (imageUpload == null) return;

        const imageRef = ref(storage, `${imagesListRef2}/${imageUpload.name + dataAtua.getTime()}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setImageUrls((prev) => [...prev, url]);
                AtualiZaListaUrl();
            });
        });
    };

    function PegarUrl_franquia(url) {
        setUrl_imgagem_franquia(url)
    }

    function ChangePastaStorage(e) {//para selecionar no combobox a pasta onde seará salvo ao arquivo no firebase
        setPastaStorage(e.target.value);
        setAtualiza(!atualiza)
    };


    function ChangeId_pesq(e) {
        setId_pesq(e.target.value);
        localStorage.setItem('id_pesq', e.target.value);   
    }

    return <>

        {
            ifLogado != "notLogad" ? <>
                <div className="row mt-2 ms-2">
                    <div className="row mt-2 ">
                        <div className="col-1 div-select">
                            <small className="text-secondary">Pastas</small>
                            <select type="text" className="form-control" onChange={ChangePastaStorage}>
                                <option value="">Selecione</option>
                                <option value="Projeto">Projeto</option>
                                <option value="Inalguração">Inalguração</option>
                                <option value="Eventos">Eventos</option>
                                <option value="Visitas">Visitas</option>
                            </select>
                            <div className="col-10 mt-2">
                                <div className="input-group">
                                    <input type="text" class="form-control" onChange={ChangeId_pesq}  value={id_pesq}/>
                                    <span className="input-group-addon">
                                        <button className="bi bi-search btn-id text-white"onClick={AtualiZaListaUrlIdDigitado}>

                                        </button>
                                    </span>
                                </div>
                            </div>

                        </div>




                        <div class=" d-flex col-9 me-3 overflow-x-scroll ">
                            {imageUrls.map((url, index) => {
                                return <>
                                    <div className="col-1 card ms-2 card-franquia" >
                                        <button onClick={() => PegarUrl_franquia(url)}><img className=" d-flex card-franquia me-2  " alt="Imagem" src={url} /></button>
                                    </div>
                                </>
                            })}
                        </div>

                        <div className="col-1">
                            <Link to="/menu-franquias" className="btn btn-success ms-4 " >Voltar</Link>
                        </div>
                    </div>
                </div>

                <div className="row d-flex ">
                    <div>
                        <div className="d-flex ms-5 mt-3 ">
                            <iframe className="ms-4 text-center frame " src={url_imgagem_franquia}></iframe>
                        </div>
                    </div>
                </div></> : navigate('/login')
        }
    </>
}
export default FranquiaFoto;