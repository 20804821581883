import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from 'moment';
import 'moment/locale/pt-br'
import api from "../../services/api.js";

import "../aniversario-colaboradores/aniversario-colaboradores.css";



function AniversarioColaboradores() {
  
  const [dados, setDados] = useState([]);
  
  const local = moment().locale('pt-br').format('LL'); //converte a data par pt-br
  const local_novo = local.slice(5); // separa o texto inicial ex 12 se setembro de 2024 ficando somente setembro...

  var Xmas95 = new Date();
  var month = Xmas95.getMonth();
  var mesAtual = month + 1;


  function PesquisarColaboradorNome() {
    let busca = ""
    let novo_array = [];

    api.get("/colaboradores_busca/", { params: { busca } })
      .then((resp) => {

        resp.data.map((item) => {
          let dtaAniver = parseInt(item.data_nasc_col.substring(3, 5));

          if (dtaAniver === mesAtual) {
            novo_array.push({
              nome: item.nome_colaborador,
              data: item.data_nasc_col,
              img: item.foto_aniver
            })
          }
        })
        // console.log(novo_array)
        setDados(novo_array);
      })
      .catch((err) => {
        setDados([]);
        alert("Erro ao carregar dados");
      });
  }

  useEffect(() => {
    PesquisarColaboradorNome()

  }, []);

  return <>

    <div className="d-flex container-fundo-col">
      <div className="d-block container-body-col">
        <div className="d-flex justify-content-between">
          <div className="col-1">
          </div>
          <h4 className="ms-3 mt-2">
            Aniversariantes: {local_novo}
          </h4>
          <div className="col-1 ">
            <Link to="/menu-aniversariantes" className="btn btn-success p-2 me-5 mt-2 btn-sm" >Voltar </Link>
          </div>
        </div>

        <div className="coluna-aniversariantes-col">

          {
            dados.map((item) => {
              return <div key={item.id_colab_fran} className="d-flex justify-content-center  ">
                <div className="col-4 ">
                  <div className="card mt-4 ms-3 " >
                    <img src={item.img} className="card-img-top img-card-col ms-5 mt-3" alt="..." />
                    <div className="card-body ms-4">
                      <h5 className="card-title">{item.nome}</h5>
                      <p className=" d-flex ">{item.data}.</p>
                    </div>
                  </div>
                  <div className="card mt-4 ms-4" >
                  </div>
                </div>
              </div>
            })
          }
        </div>
      </div>
    </div>
  </>
}
export default AniversarioColaboradores;