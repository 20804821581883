import { useState } from "react";
import Navbar from "../../components/navbar/navbar.jsx";
import { dadosLogin } from "../../zustand/zuztand";
import { useNavigate } from "react-router-dom";

import "../menufranquias/menu-franquias.css";
import { Link } from "react-router-dom";


function MenuFranquias(props) {
    const { ifLogado } = dadosLogin()
    const navigate = useNavigate();

    return <>

        {
            ifLogado != "notLogad" ? <>
                <div className="d-flex container-fundo-franquias">
                    <Navbar tela="franquias" />

                    <div className="row container-body-forn">
                        <img className="banner-fornecedores" src="https://firebasestorage.googleapis.com/v0/b/franqueadora-ce93d.appspot.com/o/banners%2Ffranquias.png?alt=media&token=48aa9149-ae26-47aa-b82a-3dc518f9f55f" alt="banner-franquias" />
                        <div className="fixed-bottom">
                            <div className="d-flex justify-content-center">
                                <div className="col-10 d-flex justify-content-between">

                                    <div className="col-2 mb-2  ">
                                        <Link to="/franquias" className={props.tela === "franquias" ? "btn-orange-destaque me-3" : "btn-orange me-3 "} aria-current="page">Dados Cadastrais</Link>
                                    </div>
                                    <div className="col-2 mb-2 ">
                                        <Link to="/franqueados" className={props.tela === "franquias" ? "btn-orange-destaque me-3" : "btn-orange me-3 "} aria-current="page">Franqueados</Link>
                                    </div>
                                    <div className="col-2 mb-2 ">
                                        <Link to="/franquias-contratos" className={props.tela === "franquias" ? "btn-orange-destaque me-3" : "btn-orange me-3 "} aria-current="page">Contratos</Link>
                                    </div>
                                    <div className="col-2 mb-2 ">
                                        <button className="btn-orange">Comunicados</button>
                                    </div>
                                    <div className="col-2 mb-2 ">
                                        <Link to="/franquia-fotos-pesq" className={props.tela === "franquias" ? "btn-orange-destaque me-3" : "btn-orange me-3 "} aria-current="page">Fotos</Link>
                                    </div>
                               
                                </div>
                            </div>

                        </div>
                    </div>







                </div></> : navigate('/login')
        }

    </>
}
export default MenuFranquias;